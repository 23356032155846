import React from 'react';

import { useAvForLawyers } from '../../hooks';

import { LawyersAccessProps } from './types';




export default function LawyerAccess({ children }: LawyersAccessProps): JSX.Element {
  const avForLawyers: boolean = useAvForLawyers();
  return <>
    {avForLawyers && children}
  </>;
}