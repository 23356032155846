import React, { useRef, useState } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import Button from '../Button';
import { CustomState, PaginationProps } from './types';


function usePaginationState(state: CustomState, initialPage: number = 0) {
  const pgState = useState<number>(initialPage);
  if (state)
    return state;
  return pgState;
}

export default function Pagination({ pagesCount, initialPage = 0, state = null, onChange = (page: number) => { } }: PaginationProps): JSX.Element {
  const [currentPage, setCurrentPage] = usePaginationState(state, initialPage);

  // const [movement, setMovement] = useState<number>(0);
  const pagesContainerRef = useRef<HTMLDivElement>(null);
  const pages = Array(pagesCount).fill(0).map((v, i) => i);

  function handleChangePage(page: number) {

    const pagesContainerWidth = pagesContainerRef?.current?.offsetWidth as number;
    // calculating the difference ( rest ) of the division ( because the width can be inexact )
    const difference = pagesContainerWidth % 58
    const position = (page + 1) * 58;
    /*
    calculating the scroll index position by divide the position by the pagesContainerWidth subtracting
    the difference
    */
    const mathFunc = currentPage > page ? Math.floor : Math.ceil;
    const counter = mathFunc(position / (pagesContainerWidth - difference)) - 1;
    // handle change the scroll position
    pagesContainerRef?.current?.scrollTo((pagesContainerWidth - (currentPage > page ? -difference : difference)) * counter, 0);
    setCurrentPage(page);
    onChange(page);
  }

  function handleNextPage() {

    const pagesContainerWidth = pagesContainerRef?.current?.offsetWidth as number;
    if (currentPage + 1 <= pagesCount - 1) {

      setCurrentPage((prevPage: number) => {
        onChange(prevPage + 1);
        return prevPage + 1;
      });
      // calculating the difference ( rest ) of the division ( because the width can be inexact )
      const difference = pagesContainerWidth % 58
      const position = (currentPage + 2) * 58;
      /*
       calculating the scroll index position by divide the position by the pagesContainerWidth subtracting
       the difference
      */
      const counter = Math.ceil(position / (pagesContainerWidth - difference)) - 1;
      // handle change the scroll position
      pagesContainerRef?.current?.scrollTo((pagesContainerWidth - difference) * counter, 0);
    }
  }
  function handleBackPage() {
    const pagesContainerWidth = pagesContainerRef?.current?.offsetWidth as number;
    if (currentPage - 1 >= 0) {
      setCurrentPage((prevPage: number) => {
        onChange(prevPage - 1);
        return prevPage - 1;
      });
      // calculating the difference ( rest ) of the division ( because the width can be inexact )
      const difference = pagesContainerWidth % 58
      const position = (currentPage - 1) * 58;
      /*
       calculating the scroll index position by divide the position by the pagesContainerWidth subtracting
       the difference
      */
      const counter = Math.ceil(position / (pagesContainerWidth - difference)) - 1;
      // handle change the scroll position
      pagesContainerRef?.current?.scrollTo((pagesContainerWidth - difference) * counter + difference, 0);
    }
  }
  return (
    <div className="flex justify-center">
      <Button
        style={{ width: 50, height: 50 }}
        onClick={handleBackPage}
        className="bg-primary text-white duration-200 font-bold text-2xl mr-1 ml-1"><IoChevronBackOutline /></Button>
      <div ref={pagesContainerRef} className="hide-scroll scroll-smooth flex flex-1 overflow-y-auto" style={{ maxWidth: '33vw', width: 'auto' }}>
        {
          pages.map((val, index) => (
            <div
              key={index}
              style={{ width: 50, height: 50 }}
              className=" mr-1 ml-1"
            >
              <Button
                style={{ width: 50, height: 50 }}
                onClick={() => handleChangePage(val)}
                className={`${currentPage === val ? 'bg-primary text-white' : 'bg-white'} duration-200 font-bold text-xl`}><>{val + 1}</></Button>
            </div>
          ))
        }
      </div>
      <Button
        style={{ width: 50, height: 50 }}
        onClick={handleNextPage}
        className="bg-primary text-white duration-200 font-bold text-2xl mr-1 ml-1"><IoChevronForwardOutline /></Button>
    </div>
  )
}