import React from 'react';
import avatar from '../../assets/images/user.png';
import { useEntityFormattedName, useUploadUrl } from '../../hooks';
import { AuthEntity } from '../../store/auth/types';



export default function Avatar(user: Partial<AuthEntity>): JSX.Element {
  const { prefix, formattedName } = useEntityFormattedName(user ?? undefined);
  const url = useUploadUrl(user?.image || '');
  return (<>
    <div className="flex py-2 w-auto items-center justify-between mt-5 ml-0 md:ml-2 lg:ml-0 overflow-hidden  lg:mt-0">
      <p className="truncate mr-2 font-normal">
        {prefix ? prefix + '.' : ''}&nbsp;
                {formattedName}
      </p>
      <div>
        {
          user.image && <img src={url || avatar} alt="lawyer or admin" style={{ width: 35, height: 35, backgroundSize: 'cover', borderRadius: '50%' }} />
        }
      </div>
    </div>
  </>)
}