import React, { ChangeEvent, useEffect, useState } from 'react';
import { IoChevronBackOutline, IoChevronDown, IoInformationCircleSharp, IoRefresh } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useEntityFormattedName } from '../../hooks';
import { Defendant } from '../../interfaces/entities';
import { setClientModal } from '../../store/client/actions';
import { fetchDefendants, setDefendantModal } from '../../store/defendant/actions';
import { DefendantType } from '../../store/defendant/types';
import { setProcessModal } from '../../store/process/actions';
import { State } from '../../store/reducers';
import ListCard from '../ListCard';
import Button from '../ui/Button';
import Dropdown from '../ui/Dropdown';
import { Input, InputGroup, Label } from '../ui/Input';
import Message from '../ui/Message';
import Pagination from '../ui/Pagination';
import { DefendantListProps } from './types';
import { ReactComponent as AddClientIllustration } from '../../assets/images/add-client-illustration.svg';
import { setLogModal } from '../../store/log/actions';
import { Link } from 'react-router-dom';
import { setPaymentModal } from '../../store/payment/actions';


export default function DefendantList({ selectList }: DefendantListProps) {
  const [search, setSearch] = useState<string>('');
  const paginationState = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>();

  const { defendants, pagesCount, defendantsCount } = useSelector((state: State): DefendantType => state.defendant);
  const dispatch = useDispatch();

  const { prefix, formattedName } = useEntityFormattedName();
  function handleChangePage(p: number) {
    setLoading(true);

    dispatch(fetchDefendants({ search, page: p }, () => {
      setLoading(false);
    }))
  }
  function handleReload() {
    setLoading(true);
    paginationState[1](0);
    dispatch(fetchDefendants({ search, page: 0 }, () => {
      setLoading(false);
    }))
  }
  function handleSearchChange(e: ChangeEvent<{ value: string; name: string | undefined }>) {
    e.persist();
    e.preventDefault();
    setLoading(true);
    clearTimeout(timer);

    const tm = setTimeout(() => {
      setSearch(e.target.value);
      dispatch(fetchDefendants({ search: e.target.value, page: paginationState[0] }, () => {
        setLoading(false);
      }))
    }, 1500);

    setTimer(tm);
  }

  // open modals


  function handleOpenModal(action: (payload: any) => void, payload: any) {
    dispatch(action(payload));
  }
  function handleOpenCreateDefendantModal() {
    dispatch(setDefendantModal({
      active: true
    }));
  }


  useEffect(() => {
    dispatch(fetchDefendants({ search, page: 0 }, () => {
      setLoading(false);
    }));
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    paginationState[1](0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defendantsCount]);

  return (
    <section className="flex items-center w-full flex-col bg-gray-100 pb-10" style={{ minHeight: 'calc(100vh - 80px)' }}>
      {
        Boolean(defendants) && Boolean(defendantsCount) && (
          <>
            <h1 className="text-4xl	text-primary font-bold mt-10 text-center">{prefix ? prefix + '.' : ''} {formattedName}</h1>

            <h1 className="text-3xl	text-gray-500 font-medium mt-5">Demandados</h1>
            <div className="flex flex-col sm:flex-row sm:justify-between w-full pr-2 pl-2 md:pr-20 md:pl-20 mb-5 mt-5 items-center">
              <InputGroup className="sm:w-3/12">
                <Label>Buscar demandado</Label>
                <Input type="text" onChange={handleSearchChange} loading={loading} placeholder="Escriba el nombre de un demandado"></Input>
              </InputGroup>
              <div className="w-full mt-5 md:mt-0 md:w-1/3 flex items-end">
                <div>
                  {selectList}
                </div>
                <InputGroup tabIndex={-1} className="ml-2" id="inputGroup">
                  <div className="w-full text-right pb-2">
                    <Label>Agregar</Label>
                  </div>
                  <Dropdown trigger={<Button className="w-full bg-white flex justify-between items-center"><>Seleccione <IoChevronDown /></></Button>}>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setClientModal, { active: true })}>Cliente</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setDefendantModal, { active: true })}>Demandado</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setProcessModal, { active: true })}> Proceso</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setLogModal, { active: true })}>Actividad</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setPaymentModal, { active: true })}>Pago</div>
                  </Dropdown>
                </InputGroup>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full pr-2 pl-2 md:pr-20 md:pl-20 pb-10">
              {defendants?.map((defendant: Defendant) => (
                <ListCard key={defendant.id} {...defendant} onDelete={handleReload} bgColor="bg-gray-200 " isDefendant />
              ))}
            </div>
            <div className="w-full pr-2 pl-2 md:pr-20 md:pl-20">
              {!defendants?.length && (<Message
                icon={<IoInformationCircleSharp size="30px" color="skyblue" />}
                container={{ className: "w-full z-50" }}
                className="text-lg font-medium shadow-lg rounded-lg"
                color="bg-white"
              >
                No se han encontrado demandados con el filtro actual
              </Message>)}
            </div>

            {pagesCount > 0 && (
              <>
                <section>
                  <Pagination state={paginationState} onChange={handleChangePage} pagesCount={pagesCount} />
                </section>
                <div className="w-full relative mt-10 md:mt-0">
                  <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15 absolute md:bottom-0 right-1 md:right-20" onClick={handleReload}>
                    <IoRefresh />
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      {
        !defendantsCount && (<section className="flex overflow-auto flex-col justify-center items-center" style={{ minHeight: 'calc(100vh - 80px)' }}>
          <>
            <h1 className="text-4xl	text-primary font-bold mt-2 text-center">{prefix ? prefix + '.' : ''} {formattedName}</h1>

            <p className="font-black text-xl sm:text-2xl mt-5 text-gray-600 mb-10 sm:mb-5 text-center">Agregue a su primer demandado</p>
            <AddClientIllustration style={{ minWidth: '300px', width: '30%' }} />
            <Button onClick={handleOpenCreateDefendantModal} className="mt-10 bg-primary text-white pr-10 pl-10" >Crear demandado</Button>
          </>
        </section>)
      }
      <div className="flex justify-start w-full p-10">

        <Link to="/member">
          <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15">
            <IoChevronBackOutline size="30px" />
          </Button>

        </Link>
      </div>
    </section>
  )
}