import { CreateClientResponse } from "../../api/client/types";

import types, {
  ClientModal,
  ClientModalAction,
  DeleteClientAction,
  Feedback,
  GetClientAction,
  GetClientResultAction,
  SetClientStateAction,
  UpdateClientAction,
  UpdateClientSuccessAction
} from "./types";

import {
  // fetch clients
  FetchClientsAction,
  FetchClientsPayload,
  // fetch clients success
  FetchClientsSuccessAction,
  FetchClientsSuccessPayload,
  // create client
  CreateClientAction
} from "./types";


//* Fetch Clients Actions

export const fetchClients = (payload: FetchClientsPayload, cb: () => void): FetchClientsAction => ({
  type: types.FETCH_CLIENTS_REQUEST,
  payload,
  cb,
})

export const fetchClientsSuccess = (payload: FetchClientsSuccessPayload): FetchClientsSuccessAction => ({
  type: types.FETCH_CLIENTS_SUCCESS,
  payload
})

//* Create Client Action

export const createClient = (cb: (request: CreateClientResponse) => void): CreateClientAction => ({
  type: types.CREATE_CLIENT_REQUEST,
  cb,
  payload: null
});

//* Set Client Modal Action

export const setClientModal = (payload: Partial<ClientModal>): ClientModalAction => ({
  type: types.SET_CLIENT_MODAL,
  payload
})
export const setCreateOrEditClientFeedback = (payload: Partial<Feedback>) => ({
  type: types.SET_CREATE_OR_EDIT_CLIENT_FEEDBACK,
  payload
})

//* Get Client Actions

export const getClient = (payload: GetClientAction['payload'], cb: GetClientAction['cb']): GetClientAction => ({
  type: types.GET_CLIENT_REQUEST,
  payload,
  cb
})
export const getClientResult = (payload: GetClientResultAction['payload']): GetClientResultAction => ({
  type: types.GET_CLIENT_RESULT,
  payload,
})

//* Set Client Action

export const setState = (payload: SetClientStateAction['payload']): SetClientStateAction => ({
  type: types.SET_CLIENT_STATE,
  payload
})



//* Update Client Actions
export const updateClient = (payload: UpdateClientAction['payload'], cb: UpdateClientAction['cb']): UpdateClientAction => ({
  type: types.UPDATE_CLIENT_REQUEST,
  payload,
  cb
})

export const updateClientSuccess = (payload: UpdateClientSuccessAction['payload']): UpdateClientSuccessAction => ({
  type: types.UPDATE_CLIENT_SUCCESS,
  payload
})

// * Delete Client Action
export const deleteClient = (payload: DeleteClientAction['payload'], cb: DeleteClientAction['cb']): DeleteClientAction => ({
  type: types.DELETE_CLIENT_REQUEST,
  payload,
  cb
});