import axios from '../axiosInstance';

import { DefendantController } from './types';

export const Defendant: DefendantController = {
  // * GET
  fetchAll: request => axios.get('/defendant', { params: request }).then(res => res.data),


  // * POST
  create: request => axios.post('/defendant', request).then(res => res.data),

  // * PUT
  update: request => axios.put('/defendant/', request).then(res => res.data),

  // * DELETE 
  delete: request => axios.delete('/defendant', { data: request }).then(res => res.data)

}

