import React, { FocusEvent, useState } from 'react';
import { DropdownProps } from './types';
import _ from 'lodash';



export default function Dropdown({ trigger, children: items, dropdownList = { className: '', width: 'w-full' }, triggerContainer = {} }: DropdownProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [id] = useState<string>(_.uniqueId('dropdown_'));

  const { className: dropdownListClassName, width: dropdownListWidth, ...dropdownListExtraProps } = dropdownList;
  function handleBlur(e: FocusEvent<HTMLElement>) {
    e.preventDefault();
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (!relatedTarget?.classList?.contains(id)) {
      setIsOpen(false);
    }
  }
  function handleToggleDropdown() {
    if (isOpen) setIsOpen(false);
    else setIsOpen(true);
  }
  return (
    <div onBlur={handleBlur} tabIndex={1} className={`z-20 ${id} w-full relative inline-block text-left outline-none`}>
      <div {...{
        ...triggerContainer, className: `${triggerContainer?.className || ''} cursor-pointer outline-none`
      }} onClick={handleToggleDropdown} >
        {trigger}
      </div>

      {
        isOpen && (
          <div className={`${dropdownListWidth}  overflow-hidden origin-top-right absolute right-0 mt-2 rounded-md shadow-xl bg-white ring-opacity-5 ${dropdownListClassName ?? ''}`} {...dropdownListExtraProps}>
            <div className="flex flex-col" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {items}
            </div>
          </div>
        )
      }
    </div >
  )
}