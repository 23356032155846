import { CreateLogResponse, DeleteAttachmentResponse, DeleteLogResponse, UpdateLogResponse } from "../../api/log/types";
import { Attachment, Log, Process } from "../../interfaces/entities";

const types: Record<string, string> = {
  CREATE_LOG_REQUEST: 'CREATE_LOG_REQUEST',

  SET_LOG_MODAL: 'SET_LOG_MODAL',

  DELETE_LOG_REQUEST: 'DELETE_LOG_REQUEST',

  UPDATE_LOG_REQUEST: 'UPDATE_LOG_REQUEST',

  SET_ATTACHMENTS_MODAL: 'SET_ATTACHMENTS_MODAL',
  DELETE_ATTACHMENT_REQUEST: 'DELETE_ATTACHMENT_REQUEST',
  DOWNLOAD_ATTACHMENTS_REQUEST: 'DOWNLOAD_ATTACHMENTS_REQUEST'
}



export interface LogType {
  logModal: {
    active: boolean;
    edition: boolean;
    form: Partial<Log> & Partial<{ private_files: File[], public_files: File[], internalId: Process['internalId']; }>
  },
  attachmentsModal: {
    active: boolean;
    private: boolean;
    log: Partial<Log>;
  }
}

export interface CreateLogAction {
  type: typeof types.CREATE_LOG_REQUEST,
  payload: Partial<{ private_files: File[], public_files: File[] }>;
  cb: (res: CreateLogResponse) => void
}

export interface SetLogModalAction {
  type: typeof types.SET_LOG_MODAL;
  payload: Partial<LogType['logModal']>;
}

export interface DeleteLogAction {
  type: typeof types.DELETE_LOG_REQUEST;
  payload: { id: Log['id'] };
  cb: (res: DeleteLogResponse) => void;
}

export interface UpdateLogAction {
  type: typeof types.UPDATE_LOG_REQUEST,
  payload: { id: Log['id'], fields: Partial<Log> };
  cb: (res: UpdateLogResponse) => void;
}

export interface SetAttachmentsModalAction {
  type: typeof types.SET_ATTACHMENTS_MODAL;
  payload: Partial<LogType['attachmentsModal']>;
}

export interface DeleteAttachmentAction {
  type: typeof types.DELETE_ATTACHMENT_REQUEST;
  payload: { id: Attachment['id'] };
  cb: (res: DeleteAttachmentResponse) => void;
}

export interface DownloadAttachmentsAction {
  type: typeof types.DOWNLOAD_ATTACHMENTS_REQUEST,
  payload: { id: number; private: boolean };
}

export type Action =
  | CreateLogAction
  | SetLogModalAction
  | DeleteLogAction
  | UpdateLogAction
  | SetAttachmentsModalAction
  | DeleteAttachmentAction
  | DownloadAttachmentsAction;

export default types; 