import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { IoCheckmarkOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { BsFileEarmarkArrowUp } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { usePortal } from '../../hooks';

import { State } from '../../store/reducers';

import Button from '../ui/Button';
import { Input, Label, InputGroup } from '../ui/Input';
import Message from '../ui/Message';
import Modal from '../ui/Modal';
import Temporal from '../ui/Temporal';


import { CreateLogProps } from './types';
import { LogType } from '../../store/log/types';
import { createLog, setLogModal, updateLog } from '../../store/log/actions';
import CreateLogModalWrapper from './styles';

export default function CreateLog({ onCreate = () => { } }: CreateLogProps): JSX.Element {
  const dispatch = useDispatch();
  const { logModal: { active, form: globalForm, edition } } = useSelector((state: State): LogType => state.log);
  const [limitMessage, setLimitMessage] = useState<boolean>(false);

  const privateFilesInput = useRef<any>();
  const publicFilesInput = useRef<any>();
  const [oldData, setOldData] = useState<Partial<LogType['logModal']['form']>>()
  const [form, setForm] = useState<LogType['logModal']['form']>({
    private_files: [],
    public_files: [],
  });
  const Portal = usePortal(<></>, 'create_log');

  function handleInputChange(e: ChangeEvent<{ value: string; name: string; }>): void {
    e.persist();
    dispatch(setLogModal({ form: { [e.target.name]: e.target.value } }));
  }
  function handleSubmit(e: FormEvent): void {
    e.persist();
    e.preventDefault();
    const portalMessage = ({ success, message }: { success: boolean; message: string; }) =>
      Portal.add(<Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg border-2 border border-gray-200 font-medium shadow-xl rounded-lg text-center md:text-left"
          color="bg-white"

        >
          {success ? `Se ha ${edition ? 'modificado' : 'creado'} la Actividad correctamente` : message}
        </Message>
      </Temporal>)

    if (!edition) {
      dispatch(createLog(form, ({ success, message }) => {
        dispatch(setLogModal({ active: false, edition: false, form: {} }));
        portalMessage({ success, message });
        if (success) {
          onCreate();
          setForm({ private_files: [], public_files: [] })
        }
      }))
    } else {
      const { id, content, date } = globalForm;
      dispatch(updateLog({ id: id as number, fields: { content, date } }, ({ success, message }) => {
        const form = _.mapValues(globalForm, () => undefined)
        dispatch(setLogModal({ active: false, edition: false, form }));
        portalMessage({ success, message });
        if (success) {
          onCreate();
          setForm({ private_files: [], public_files: [] })
        }
      }));
    }

  }

  function handleAddFile(e: ChangeEvent<{ type: string; name: string | undefined; files: FileList | null }>, key: string) {
    e.persist();

    const files: File[] = Array.from(e.target.files || []);

    const someoneSizeGreaterThanTheLimit = files.some((file) => ((file.size / 1000) / 1000) > 100);

    if (someoneSizeGreaterThanTheLimit) setLimitMessage(true);

    else {
      setLimitMessage(false);
      setForm(prevState => ({ ...prevState, [key]: files }));
    }
  }

  function handleClose() {

    dispatch(setLogModal({
      active: false, edition: false,
      form: { content: '', date: '', internalId: undefined, processId: undefined }
    }));
    setForm({ private_files: [], public_files: [] })
    onCreate();
  }

  useEffect(() => {
    setOldData({ ...globalForm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <CreateLogModalWrapper>
      <Modal isActive={active} onClose={handleClose}>
        <form onSubmit={handleSubmit} className="flex justify-start flex-col">
          <div className="w-full flex justify-center sm:justify-between mb-5 flex-col sm:flex-row font-bold items-center">
            <p className="text-2xl">{edition ? 'Modificar Actividad' : 'Agregar Actividad'}</p>
            <div>
              <Input defaultValue={moment.utc(globalForm?.date || new Date()).format('YYYY-MM-DD')} type="date" name="date" onChange={handleInputChange}></Input>
            </div>
          </div>
          {
            (!Boolean(oldData?.internalId) && !Boolean(oldData?.processId)) && (<>
              <InputGroup className="mb-4">
                <Label color="text-gray-600" className="text-base">
                  Proceso:
              </Label>
                <Input autoFocus onChange={handleInputChange} name="internalId" required type="number" placeholder="Ingrese un número de proceso " />
              </InputGroup>
            </>)
          }

          <InputGroup className="mb-5">
            <Label color="text-gray-600" className="text-base">Contenido:</Label>
            <Input onChange={handleInputChange} defaultValue={globalForm?.content} name="content" required type="text" multiline placeholder="Ingrese el contenido" />
          </InputGroup>


          {
            limitMessage && (
              <p className="text-red font-bold text-sm text-justify mb-5">
                Los archivos seleccionados no pueden superar los 100mb, por lo tanto, su ultima seleccion fue descartada.
              </p>
            )
          }

          {
            !edition && (
              <div className="grid mb-8 md:grid-cols-2 gap-1 w-full">

                <div className="w-full md:w-auto">
                  <Label color="text-gray-600" className="text-base">Adjunto Privado:</Label>

                  <div onClick={() => privateFilesInput.current.click()} className="cursor-pointer mt-2 border-2 rounded-md p-5 border-dashed border-gray-200 flex justify-center items-center">
                    {
                      form?.private_files?.length ? (
                        <ul className="w-full">
                          {
                            form.private_files.map((file, index) => (
                              <li key={index} title={file.name} className="text-sm bg-red bg-opacity-20 mb-2 rounded-md p-2">
                                <p className="overflow-ellipsis truncate">{file.name}</p>
                              </li>
                            ))
                          }
                        </ul>
                      ) : <BsFileEarmarkArrowUp size="23px" />
                    }
                    <input multiple type="file" ref={privateFilesInput} onChange={(e) => handleAddFile(e, 'private_files')} style={{ display: 'none' }} />

                  </div>
                </div>
                <div className="w-full md:w-auto">
                  <Label color="text-gray-600" className="text-base">Adjunto Publico:</Label>

                  <div onClick={() => publicFilesInput.current.click()} className="cursor-pointer mt-2 border-2 rounded-md p-5 border-dashed border-gray-200 flex justify-center items-center">
                    {
                      form?.public_files?.length ? (
                        <ul className="w-full">
                          {
                            form.public_files.map((file, index) => (
                              <li key={index} title={file.name} className="text-sm bg-green bg-opacity-20 mb-2 rounded-md p-2">
                                <p className="overflow-ellipsis truncate">{file.name}</p>
                              </li>
                            ))
                          }
                        </ul>
                      ) : <BsFileEarmarkArrowUp size="23px" />
                    }
                    <input multiple type="file" ref={publicFilesInput} onChange={(e) => handleAddFile(e, 'public_files')} style={{ display: 'none' }} />

                  </div>
                </div>
              </div>
            )
          }
          <Button type="submit" className="w-full bg-primary text-white"><>{edition ? 'Guardar cambios' : 'Crear Actividad'}</></Button>

        </form>
      </Modal>
    </CreateLogModalWrapper >
  )
}