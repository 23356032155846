import types, { UserType, Action, FetchLawyersSuccessAction, SetLawyerModalAction } from './types';


const initialState: UserType = {
  lawyers: [],
  userModal: {
    active: false,
    edition: false,
    form: {}
  }
}
export default function UserReducer(state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_LAWYERS_SUCCESS: {
      const lawyers = payload as FetchLawyersSuccessAction['payload'];
      return {
        ...state,
        lawyers
      }
    }
    case types.SET_LAWYER_MODAL: {
      const value = payload as Partial<SetLawyerModalAction['payload']>;
      return {
        ...state, userModal: {
          active: value?.active ?? state.userModal.active,
          edition: value?.edition ?? state.userModal.edition,
          form: { ...state.userModal.form, ...(value?.form || {}) }
        }
      }
    }
    default:
      return state;
  }

}