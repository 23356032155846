import React from 'react';
import { CardProps } from './types';


export default function Card({ image = null, children, className = '', onClick = () => { }, ...extraProps }: CardProps): JSX.Element {
  return (
    <div onClick={onClick} className={`shadow-md bg-white flex flex-col justify-between rounded-lg border border-gray-100 ${className}`} {...extraProps}>
      {image !== null && (
        <div className="image  p-2 mb-2 flex justify-center">
          {image}
        </div>
      )}
      {children}
    </div>
  )
}