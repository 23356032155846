import React, { ChangeEvent, FormEvent } from 'react';
import _ from 'lodash';

import { IoCheckmarkOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';

import { usePortal } from '../../hooks';

import { createClient, setClientModal, updateClient } from '../../store/client/actions';
import { ClientType } from '../../store/client/types';
import { State } from '../../store/reducers';

import Button from '../ui/Button';
import { Input, Label, InputGroup } from '../ui/Input';
import Message from '../ui/Message';
import Modal from '../ui/Modal';
import Tabs from '../ui/Tabs';
import { Tab } from '../ui/Tabs/types';

import CreateClientModalWrapper from './styles';

import { CreateClientProps, LegalPersonTab } from './types';
import Temporal from '../ui/Temporal';

export default function CreateClient({ onEdit = () => { } }: CreateClientProps): JSX.Element {
  const dispatch = useDispatch();
  const { clientModal: { active, edition, form } } = useSelector((state: State): ClientType => state.client);
  const Portal = usePortal(<></>, 'create_client');


  const tabs: Tab<LegalPersonTab>[] = [
    {
      content: 'Persona Natural',
      value: { index: 0 }
    }, {
      content: 'Persona Juridica',
      value: { index: 1 }
    }]

  function handleInputChange(e: ChangeEvent<{ value: string; name: string; }>): void {
    dispatch(setClientModal({ form: { [e.target.name]: e.target.value } }))

  }
  function handleSubmit(e: FormEvent): void {
    e.persist();
    e.preventDefault();
    const portalMessage = ({ success, message }: { success: boolean; message: string; }) =>
      Portal.add(<Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium border-2 border border-gray-200 shadow-xl rounded-lg text-center md:text-left"
          color="bg-white"

        >
          {success ? `Se ha ${edition ? 'modificado' : 'creado'} el cliente correctamente` : message}
        </Message>
      </Temporal>)

    if (edition) {
      const { id, ...rest } = form;
      dispatch(
        updateClient({ id: id as number, fields: rest }, ({ success, message }) => {
          const client = _.mapValues(form, () => undefined);
          dispatch(setClientModal({ active: false, form: { ...client } }));
          portalMessage({ success, message });
          onEdit();
        })
      );
    } else {
      dispatch(createClient(({ success, message }) => {
        dispatch(setClientModal({ active: false, form: {} }));
        portalMessage({ success, message });
      }))
    }
  }
  function handleSelectLegalPerson(tab: Tab<LegalPersonTab>) {
    dispatch(setClientModal({ form: { legalPerson: Boolean(tab.value.index) } }))

  }
  function handleClose() {
    dispatch(setClientModal({ active: false }));
  }
  return (
    <CreateClientModalWrapper>
      <Modal isActive={active} onClose={handleClose}>
        <form onSubmit={handleSubmit} className="flex justify-start flex-col">
          <div className="w-full flex justify-center sm:justify-between mb-5 flex-col sm:flex-row font-bold items-center">
            <p className="text-2xl">{edition ? 'Modificar' : 'Crear'} cliente</p>
            <Tabs<LegalPersonTab> defaultTab={form.legalPerson ? 1 : 0} className="justify-center" onSelect={handleSelectLegalPerson} tabs={tabs} />
          </div>
          <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Identificación</Label>

          <InputGroup className="mb-4">
            <Label color="text-gray-600" className="text-base">Nombre:</Label>
            <Input autoFocus onChange={handleInputChange} defaultValue={form?.name || ''} name="name" required type="text" placeholder="Ingrese un Nombre" />
          </InputGroup>
          <InputGroup className="mb-4">
            <Label color="text-gray-600" className="text-base">RUC / CI: </Label>
            <Input onChange={handleInputChange} defaultValue={form?.officialId || ''} name="officialId" required type="text" placeholder="Ingrese un RUC/CI" />
          </InputGroup>
          {
            form.legalPerson ? (
              <InputGroup className="mb-4">
                <Label color="text-gray-600" className="text-base">Gerente general:</Label>
                <Input onChange={handleInputChange} defaultValue={form?.generalManager || ''} name="generalManager" type="text" placeholder="Ingrese el nombre del gerente general" />
              </InputGroup>
            ) : (null)
          }
          <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Ubicación</Label>

          <InputGroup className="mb-4">
            <Label color="text-gray-600" className="text-base">Dirección:</Label>
            <Input onChange={handleInputChange} defaultValue={form?.address || ''} name="address" type="text" placeholder="Ingrese una dirección" />
          </InputGroup>
          <div className="grid grid-cols-2 gap-1">
            <InputGroup className="mb-4">
              <Label color="text-gray-600" className="text-base">Número de contacto:</Label>
              <Input onChange={handleInputChange} defaultValue={form?.phone || ''} name="phone" type="tel" placeholder="Ingrese un número de contacto" />
            </InputGroup>
            <InputGroup className="mb-4">
              <Label color="text-gray-600" className="text-base">Correo electrónico:</Label>
              <Input onChange={handleInputChange} defaultValue={form?.email || ''} name="email" type="email" placeholder="Ingrese un correo electrónico" />
            </InputGroup>
          </div>
          <Button type="submit" className="w-full bg-primary text-white"><>{edition ? 'Guardar cambios' : 'Crear cliente'}</></Button>
        </form>
      </Modal>
    </CreateClientModalWrapper>
  )
}