import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { IoCheckmarkOutline, IoCloseCircleSharp } from 'react-icons/io5';


import { Input, InputGroup, Label } from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import Select from '../../components/ui/Select';
import { Option } from '../../components/ui/Select/types';
import Message from '../../components/ui/Message';
import { ReactComponent as BrandLogo } from '../../assets/images/brandLogo-white.svg';

import { signIn } from '../../store/auth/actions';
import { SignInPayload } from '../../store/auth/types';
import { FeedbackState, HomePageProps } from './types';
import { State } from '../../store/reducers';

import ManIcon from '../../assets/images/man-icon.png';
import JusticeIcon from '../../assets/images/justice-icon.png';




export default function HomePage({ match }: HomePageProps): JSX.Element {
  const { isLoggedIn } = useSelector((state: State) => state.auth);
  const [form, setForm] = useState<SignInPayload>({ email: '', password: '', type: 'client' })
  const [feedback, setFeedback] = useState<FeedbackState>({ success: false, message: '', active: false })
  const router = useHistory();
  const dispatch = useDispatch()

  const options: Option<'user' | 'client'>[] = [
    {
      text: 'Cliente',
      image: ManIcon,
      value: 'client'
    },
    {
      text: 'Abogado',
      image: JusticeIcon,
      value: 'user'
    }
  ]
  function handleInputChange(e: ChangeEvent<{ value: string; name: string; }>) {
    setForm((prev: SignInPayload) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  function handleSelectChange(value: 'user' | 'client') {
    setForm((prev: SignInPayload) => ({ ...prev, type: value }))
  }
  function handleSubmit(e: FormEvent): void {
    e.preventDefault();
    dispatch(signIn(form, ({ success, message }) => {
      setFeedback({ success, message, active: true });
    }))
  }
  useEffect(() => {
    if (isLoggedIn) router.push('/member');
  });
  return (
    <div className="h-screen grid grid-cols-1 md:grid-cols-2">
      <div className="bg-cover bg-center shadow-md" style={{ filter: 'brightness(80%) ', backgroundImage: `url(https://cdn.pixabay.com/photo/2015/02/01/18/27/hammer-620011_960_720.jpg)` }}>
      </div>
      <div className="p-5 bg-primary relative h-full flex flex-col flex-1 items-center justify-center">
        <form className="w-3/5 flex flex-col flex-1 items-center justify-center" onSubmit={handleSubmit}>
          {/* <img alt="Brand Logo" style={{ filter: 'invert(100%)' }} className="w-1/4 mb-14" src="https://cdn.pixabay.com/photo/2017/02/01/09/55/arrow-2029273_960_720.png" /> */}
          <BrandLogo className="mb-10" style={{
            width: '100%'
          }} />
          {feedback.active && (
            <Message
              icon={feedback.success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
              container={{ className: "absolute bottom-0 z-20 w-full" }}
              className="text-lg font-medium"
              closable
              onClose={() => setFeedback((prev: FeedbackState) => ({ ...prev, active: false }))}
            >
              {feedback.success ? 'Seras rediccionado en un momento' : feedback.message}
            </Message>
          )}
          <Select<'user' | 'client'> label={<Label color="text-white">Soy un/a</Label>} onChange={handleSelectChange} className="mb-5" options={options} />
          <InputGroup>
            <Label color="text-white">{form.type === 'user' ? 'Ingrese su usuario' : 'Ingrese su RUC/CI'}</Label>
            <Input onChange={handleInputChange} name="email" type={form.type === 'user' ? 'email' : 'text'} placeholder={form.type === 'user' ? 'ej: impegno@gmail.com' : 'ej: 217321832818'} />
          </InputGroup>
          <InputGroup className="mt-5">
            <Label color="text-white">Ingrese su contraseña</Label>
            <Input onChange={handleInputChange} name="password" type="password" placeholder="ej: 1234567" />
          </InputGroup>
          <Button type="submit" className="bg-yellow transform-gpu hover:scale-105 duration-200 text-white w-full mt-8 font-bold" style={{ border: '0px' }}>INGRESAR</Button>
        </form>

      </div>
    </div>
  )
}