import React from 'react';
import { IoAddSharp, IoRefresh } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';

import { setPaymentModal } from '../../store/payment/actions';
import { State } from '../../store/reducers';
import LawyerAccess from '../LawyerAccess';
import PaymentCard from '../PaymentCard';
import Button from '../ui/Button';
import { PaymentsListProps } from './types';

export default function PaymentsList(props: PaymentsListProps): JSX.Element {
  const dispatch = useDispatch();
  const process = useSelector((state: State): State['process']['gettedProcess'] => state.process.gettedProcess);
  function openCreatePaymentModal() {
    dispatch(setPaymentModal({ active: true, form: { processId: process?.id } }));
  }
  return (
    <div className="flex flex-col justify-between">
      <LawyerAccess>
        <div className="p-2 md:p-10">
          <div className="w-full flex mb-5 justify-between items-center flex-col md:flex-row">
            <h3 className="text-2xl font-bold text-center md:text-left mb-5 md:mb-0">
              Historial de Pagos
            </h3>
            <div className="flex">
              <Button onClick={openCreatePaymentModal} className="bg-primary text-white">
                <IoAddSharp size="25px" />
              </Button>
            </div>
          </div>

          {[...(process?.payments || [])]
            .sort((a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime())
            .map((pm, index) => (
              <React.Fragment key={index}>
                <PaymentCard handleReload={props.handleReload} payment={pm} />
              </React.Fragment>
            ))}
        </div>

      </LawyerAccess>
      <div className="w-full flex justify-end pb-8 pr-10">
        <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15" onClick={props.handleReload}>
          <IoRefresh />
        </Button>

      </div>
    </div>
  )
}