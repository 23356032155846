import { useEffect, useState } from 'react';
import { roles } from '../helpers/constants';
import { useUserRole } from './useUserRole';


const lawyerRoles: string[] = [roles.ADMINISTRATOR, roles.LAWYER];

export function useAvForLawyers(): boolean {
  const [access, setAccess] = useState<boolean>(false);
  const role: string = useUserRole();

  useEffect(() => {
    if (lawyerRoles.includes(role))
      setAccess(true);
    else
      setAccess(false);

  }, [role])

  return access;
}