import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { Process, ToDo } from '../../api';

import { State } from '../reducers';
import { createProcessFailed, createProcessSuccess, getProcessSuccess, updateProcessSuccess } from './actions';
import types, { CreateProcessAction, GetProcessAction, ProcessType, SetToDoAction, UpdateProcessAction } from './types';

function* createProcess({ cb }: CreateProcessAction): SagaIterator {
  try {
    const { processModal: { form } } = yield select((state: State): ProcessType => state.process);

    const { success, message } = yield call(Process.create, form);
    yield call(cb, { success, message });
    if (success) {
      yield put(createProcessSuccess());
    } else {
      yield put(createProcessFailed())
    }
  } catch (e) {
    yield put(createProcessFailed())
    yield call(cb, {
      success: false, message: 'Ocurrió un error inesperado'
    });
  }
}

function* getProcess({ payload, cb }: GetProcessAction): SagaIterator {
  try {
    const res = yield call(Process.getProcess, payload);
    if (res?.success) {
      yield put(getProcessSuccess(res?.process))
    }

    yield call(cb, res);
  }
  catch (e) {
    yield call(cb, { success: false, message: '' });
  }
}

function* updateProcess({ cb, payload }: UpdateProcessAction): SagaIterator {
  try {
    const res = yield call(Process.update, payload);
    yield call(cb, res);
    if (res?.success)
      yield put(updateProcessSuccess(payload));


  } catch (e) {
    console.log(e);
    yield call(cb, {
      success: false, message: 'Ocurrió un error inesperado'
    });
  }
}

function* setToDo({ payload, cb }: SetToDoAction): SagaIterator {
  try {
    const res = yield call(ToDo.setTodo, payload);
    console.table(res);
    yield call(cb, res);

  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });
  }
}

export default function* processSaga(): SagaIterator {
  yield takeLatest(types.CREATE_PROCESS_REQUEST, createProcess);
  yield takeLatest(types.UPDATE_PROCESS_REQUEST, updateProcess);
  yield takeLatest(types.GET_PROCESS_REQUEST, getProcess);
  yield takeLatest(types.SET_TODO_REQUEST, setToDo);
}