import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';


import { setProcessModal, createProcess, updateProcess } from '../../store/process/actions';
import { ProcessType } from '../../store/process/types';

import { State } from '../../store/reducers';

import Button from '../ui/Button';
import { Input, InputGroup, Label } from '../ui/Input';
import Modal from '../ui/Modal';

import CreateProcessWrapper from './styles';
import { CreateProcessProps, CreateProcessTab } from './types';
import { Process } from '../../interfaces/entities/Process.interface';

import { HiDocument, HiUsers } from 'react-icons/hi';
import { CustomButtonComponentProps, Tab } from '../ui/Tabs/types';
import Tabs from '../ui/Tabs';
import Select from '../ui/Select';

import { processStatus, processStatusColors, usePortal } from '../../hooks';
import { Option } from '../ui/Select/types';

import Temporal from '../ui/Temporal';
import Message from '../ui/Message';
import { IoCheckmarkOutline, IoCloseCircleSharp } from 'react-icons/io5';
import Avatar from '../Avatar';
import { AuthType } from '../../store/auth/types';
import { User } from '../../interfaces/entities';



export default function CreateProcess({ onEdit = () => { } }: CreateProcessProps) {
  const [currentTab, setCurrentTab] = useState<CreateProcessTab>(1);

  const { processModal: { active, form, edition = false } } = useSelector((state: State): ProcessType => state.process);
  const { entity: user } = useSelector((state: State): AuthType => state.auth);

  const Portal = usePortal();
  const dispatch = useDispatch();




  const statusOptions: Option<Process['status']>[] = Object.keys(processStatus).map((status: any) => ({
    text: (
      <div className="w-full flex justify-between">
        <span className="font-bold mr-2 truncate" style={{ color: processStatusColors[status] }}>{processStatus[status as number]}</span>
        <div className="w-1"></div>
        <span className="text-right font-bold" style={{ color: processStatusColors[status] }}>{status}%</span>
      </div>
    ),
    value: status
  }));

  const tabs: Tab<CreateProcessTab>[] = [
    {
      content: <HiDocument />,
      value: 1
    },
    {
      content: <HiUsers />,
      value: 2
    },
  ]
  function handleClose() {

    dispatch(setProcessModal({
      active: false,
      form: {
        ..._.mapValues(form, () => undefined)
      },
      edition: false,
    }));
  }
  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    e.persist();
    const portalMessage = ({ success, message }: { success: boolean, message: string | null }) => Portal.add(
      <Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium shadow-xl border-2 border border-gray-200 rounded-lg  text-center md:text-left"
          color="bg-white"
        >
          {!success ? `${message}` : `Se ha ${edition ? 'editado' : 'creado'} el proceso correctamente`}
        </Message>
      </Temporal>
    );

    if (edition) {
      const { id, ...rest } = _.pick(form, [
        "id",
        "status",
        "adminId",
        "internalId",
        "demandantName",
        "demandantOfficialId",
        "defendantId",
        "debtAmount",
        "demandedAmount",
        "judgeName",
        "secretaryName",
        "summonsName",
        "summonsPhone",
        "depositaryName",
        "depositaryPhone",
        "assignedPolice",
        "assignedPolicePhone",
        "designatedProficient",
        "designatedProficientPhone",
        "judicialUnit",
        "building",
        "note",
        "defendantOfficialId",
        "defendantName",
        "createdAt"
      ]);
      dispatch(updateProcess({ id: id as number, fields: rest }, (res) => {
        if (res?.success) onEdit();
        portalMessage(res)
      }));
    } else {
      dispatch(createProcess((res) => {
        portalMessage(res)
        if (res?.success) onEdit();

      }));

    }
    dispatch(setProcessModal({
      active: false, edition: false, form: {
        ..._.mapValues(form, () => undefined)
      }
    }))

  }
  function handleInputChange(e: ChangeEvent<{ value: string; name: string | undefined; type: string; }>) {
    e.persist();

    const { name, value, type } = e.target;
    dispatch(setProcessModal({ active: true, edition, form: { [name as string]: type === typeof 1 ? Number(value) : value } }))
  }
  function handleTabSelect(tab: Tab<CreateProcessTab>) {
    setCurrentTab(tab.value);
  }
  function handleStatusChange(status: Process['status']) {
    dispatch(setProcessModal({ active: true, form: { status: Number(status) } }))

  }
  return (
    <CreateProcessWrapper>
      <Modal isActive={active} onClose={handleClose}>
        <form onSubmit={handleSubmit} className="flex justify-start flex-col">
          <div className=" w-full mb-5 flex bg-white justify-center sm:justify-between flex-col sm:flex-row font-bold items-center">
            <div className="flex w-full lg:w-auto flex-shrink flex-col lg:flex-row  items-center">
              <p className="text-2xl whitespace-nowrap">{edition ? 'Modificar Proceso' : 'Crear Proceso'}</p>
              <div className='md:ml-2 mb-0.5 w-full lg:w-auto lg:flex-shrink'>
                <Select<Process['status']> options={statusOptions} defaultValue={statusOptions.find((status) => Number(status?.value) === Number(form?.status))} onChange={handleStatusChange} />
              </div>
              <div className="md:ml-2 mt-2 lg:mt-0 w-full lg:w-auto lg:flex-shrink">
                <Input autoFocus onChange={handleInputChange} style={{ height: 42 }} name="createdAt" value={moment(form?.createdAt).format('YYYY-MM-DD') ?? ''} type="date" placeholder="Ingrese un Nombre" />
              </div>
            </div>
            <Avatar {...user as Partial<User>} />

          </div>
          {
            currentTab === 1 ? (
              <>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* 
                // * Process Data
              */}
                  <div className="w-full flex flex-col">
                    <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Datos del Proceso</Label>

                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Número</Label>
                      <Input onChange={handleInputChange} name="internalId" value={form?.internalId ?? ''} type="string" placeholder="Número de proceso" />
                    </InputGroup>
                    {/* 
                // * Demandant Data
               */}
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <InputGroup className="mb-4">
                          <Label color="text-gray-600" className="text-base">Demandante:</Label>
                          <Input onChange={handleInputChange} name="demandantName" value={form?.demandantName ?? ''} type="text" placeholder="Nombre del demandante" />
                        </InputGroup>
                      </div>
                      <div>
                        <InputGroup className="mb-4">
                          <Label color="text-gray-600" className="text-base">RUC/CI</Label>
                          <Input required={!edition && !Boolean(form?.clientId)} onChange={handleInputChange} name="demandantOfficialId" value={form?.demandantOfficialId ?? ''} type="text" placeholder="RUC/CI  del demandante" />
                          {!edition && !Boolean(form?.clientId) ? (
                            <Label style={{ fontSize: 9 }} color={form.demandantOfficialId ? 'text-gray-400' : 'text-red font-bold'}>* requerido para asignar el proceso a un cliente</Label>
                          ) : (<></>)}
                        </InputGroup>
                      </div>
                    </div>
                    {/* 
                // * Defendant Data
               */}
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <InputGroup className="mb-4">
                          <Label color="text-gray-600" className="text-base">Demandado:</Label>
                          <Input onChange={handleInputChange} name="defendantName" defaultValue={edition ? form.defendant?.name : form?.defendantName ?? ''} type="text" placeholder="Nombre del demandado" />
                        </InputGroup>
                      </div>
                      <div>
                        <InputGroup className="mb-4">
                          <Label color="text-gray-600" className="text-base">RUC/CI</Label>
                          <Input onChange={handleInputChange} name="defendantOfficialId" defaultValue={edition ? form.defendant?.officialId : form?.defendantOfficialId ?? ''} type="text" placeholder="RUC/CI  del demandado" />
                        </InputGroup>
                      </div>
                    </div>
                    <div className="flex flex-col mt-5">
                      <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Valores</Label>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Monto de la deuda</Label>
                        <Input onChange={handleInputChange} name="debtAmount" value={form?.debtAmount ?? ''} type="text" placeholder="Monto de la deuda" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Cuantia</Label>
                        <Input onChange={handleInputChange} name="demandedAmount" value={form?.demandedAmount ?? ''} type="text" placeholder="Cuantia" />
                      </InputGroup>
                    </div>
                  </div>
                  {/* 
                    // * Process Location Data
                  */}
                  <div className="w-full flex flex-col">
                    <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Ubicación</Label>

                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Unidad Judicial:</Label>
                      <Input onChange={handleInputChange} name="judicialUnit" value={form?.judicialUnit ?? ''} type="text" placeholder="Unidad judicial" />
                    </InputGroup>

                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Torre:</Label>
                      <Input onChange={handleInputChange} name="building" value={form?.building ?? ''} type="text" placeholder="Torre" />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Nota:</Label>
                      <Input onChange={handleInputChange} name="note" value={form?.note ?? ''} type="text" placeholder="Nota del proceso" />
                    </InputGroup>
                    <div className="flex flex-col mt-5">
                      <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Juez - Secretario</Label>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Nombre del Juez:</Label>
                        <Input onChange={handleInputChange} name="judgeName" value={form?.judgeName ?? ''} type="text" placeholder="Nombre del juez" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Nombre del secretario:</Label>
                        <Input onChange={handleInputChange} name="secretaryName" value={form?.secretaryName ?? ''} type="text" placeholder="Nombre del secretario" />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="w-full flex flex-col">
                    <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Citador - Depositario</Label>
                    <Label color="text-gray-600" className="text-lg mb-3 font-semibold">Citador:</Label>

                    {/* 
                      // * Summons Data
                   */}
                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Nombre del citador:</Label>
                      <Input onChange={handleInputChange} name="summonsName" value={form?.summonsName ?? ''} type="text" placeholder="Nombre del citador" />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Número del citador:</Label>
                      <Input onChange={handleInputChange} name="summonsPhone" value={form?.summonsPhone ?? ''} type="number" placeholder="Número del citador" />
                    </InputGroup>

                    {/* 
                      // * Depositary Data
                   */}

                    <div className="flex flex-col mt-5">
                      <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Depositario</Label>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Nombre del depositario</Label>
                        <Input onChange={handleInputChange} name="depositaryName" value={form?.depositaryName ?? ''} type="text" placeholder="Nombre del depositario" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Número del depositario</Label>
                        <Input onChange={handleInputChange} name="depositaryPhone" value={form?.depositaryPhone ?? ''} type="number" placeholder="Número del depositario" />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="w-full flex flex-col">
                    <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Perito - Policía</Label>
                    <Label color="text-gray-600" className="text-lg mb-3 font-semibold">Perito:</Label>

                    {/* 
                      // * Designated Proficient Data
                   */}
                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Nombre del perito:</Label>
                      <Input onChange={handleInputChange} name="designatedProficient" value={form?.designatedProficient ?? ''} type="text" placeholder="Nombre del perito" />
                    </InputGroup>

                    <InputGroup className="mb-4">
                      <Label color="text-gray-600" className="text-base">Número del perito:</Label>
                      <Input onChange={handleInputChange} name="designatedProficientPhone" value={form?.designatedProficientPhone ?? ''} type="number" placeholder="Número del perito" />
                    </InputGroup>

                    {/* 
                      // * Depositary Data
                   */}

                    <div className="flex flex-col mt-5">
                      <Label color="text-gray-600" className="text-xl mb-3 font-semibold">Policía:</Label>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Nombre del Policía</Label>
                        <Input onChange={handleInputChange} name="assignedPolice" value={form?.assignedPolice ?? ''} type="text" placeholder="Nombre del Policía" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Label color="text-gray-600" className="text-base">Número del Policía </Label>
                        <Input onChange={handleInputChange} name="assignedPolicePhone" value={form?.assignedPolicePhone ?? ''} type="number" placeholder="Número del Policía" />
                      </InputGroup>
                    </div>
                  </div>



                </div>
              )
          }
          <div className="flex justify-between items-center mt-5">
            <div className="flex items-center">
              <Tabs<CreateProcessTab>
                onSelect={handleTabSelect}
                tabs={tabs}
                defaultTab={0}
                CustomButtonComponent={({ onClick, active, children }: CustomButtonComponentProps) => <Button onClick={onClick} type="button" className={`${active ? 'bg-primary text-white' : ''} mr-2 text-2xl`} padding="p-2">{children}</Button>} />
            </div>
            <div>
              <Button type="submit" className="w-full bg-primary text-white" padding="px-10 py-3">
                <>{edition ? 'Guardar cambios' : 'Crear proceso'} </>
              </Button>
            </div>
          </div>

        </form>
      </Modal>
    </CreateProcessWrapper >
  )
}
