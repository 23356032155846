import { ClientController } from "./types";
import axios from '../axiosInstance';

export const Client: ClientController = {
  // * GET
  fetchAll: request => axios.get('/client', { params: request }).then(res => res.data),
  getClient: request => axios.get(`/client/getClient/${request.id}`).then(res => res.data),

  // * POST
  create: request => axios.post('/client', request).then(res => res.data),

  // * PUT
  update: request => axios.post('/client/update', request).then(res => res.data),

  // * DELETE
  delete: request => axios.delete('/client', { data: request }).then(res => res.data)

}

