import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';


export function usePortal(cmp: JSX.Element = <></>, id = '') {
  const [Component, setComponent] = useState<JSX.Element>(cmp);

  const add = (cmp: JSX.Element) => setComponent(cmp);

  useEffect(() => {
    const divs: HTMLDivElement[] = Array.from(document.querySelectorAll('div'), (div) => div)
      .filter((div: HTMLDivElement) => div.classList.contains('portal') && div.innerHTML === "");

    // Clean junk divs
    for (const div of divs) {
      div.remove();
    }

    if (id)
      document.querySelector(`.portal-${id}`)?.remove();

    const toRenderDiv = document.createElement('div');

    toRenderDiv.className = `portal${id ? '-' + id : ''}`; // set className

    ReactDOM.render(
      Component,
      document.body.appendChild(toRenderDiv)
    )
  }, [Component, id]);

  return {
    add
  }
}