import axios from '../axiosInstance';
import { ToDoController } from './types';




export const ToDo: ToDoController = {

  // DELETE 
  setTodo: request => axios.post('/toDo', request).then(res => res.data)
}