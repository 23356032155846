import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IoCheckmarkOutline, IoCloseCircleSharp, IoEllipsisVertical } from 'react-icons/io5';

import { deleteDefendant, setDefendantModal } from '../../store/defendant/actions';
import { Client, Defendant } from '../../interfaces/entities';
import { deleteClient, setClientModal } from '../../store/client/actions';

import Card from '../ui/Card';
import { ReactComponent as BlueBrandIcon } from '../../assets/images/blue-brand-icon.svg';
import Dropdown from '../ui/Dropdown';
import Button from '../ui/Button';
import ToggleSwitch from '../ui/ToggleSwitch';
import { useUserRole } from '../../hooks/useUserRole';

import { ListCardProps } from './types';
import { usePortal } from '../../hooks';
import Temporal from '../ui/Temporal';
import Message from '../ui/Message';
import { roles } from '../../helpers/constants';
import { DropdownItem } from '../ui/Dropdown/Item';
import Confirm from '../ui/Confirm';

export default function ListCard({ bgColor = 'bg-skyblue', isDefendant = false, onClick = () => { }, onDelete = () => { }, onDisabledChange = () => { }, ...entity }: ListCardProps): JSX.Element {
  const dispatch = useDispatch();
  const role = useUserRole();
  const Portal = usePortal(<></>, 'delete_entity');
  const [confirmDeleteEntityModal, setConfirmDeleteEntityModal] = useState<boolean>(false);
  function handleEdit() {
    if (isDefendant) {
      dispatch(setDefendantModal({ active: true, edition: true, form: { ...entity as Partial<Defendant> } }))
    } else {
      dispatch(setClientModal({ active: true, edition: true, form: { ...entity as Partial<Client> } }))
    }
  }
  function handleDelete() {
    const portalMessage = ({ success, message }: { success: boolean; message: string; }) => Portal.add(<Temporal duration={6000}>
      <Message
        icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
        container={{
          className: "fixed bottom-10  z-20 w-5/6  z-50",
          style: { marginLeft: 'calc(calc(100% / 6) / 2)' }
        }}
        className="text-lg font-medium shadow-xl border-2 border border-gray-200 rounded-lg text-center md:text-left"
        color="bg-white"
      >
        {!success ? message : `Se ha eliminado el ${isDefendant ? 'demandado' : 'cliente'} correctamente`}
      </Message>
    </Temporal>)
    if (isDefendant) {
      dispatch(deleteDefendant({ id: entity.id as Defendant['id'] }, (res) => {
        portalMessage(res);
        onDelete();
      }))
    } else {
      dispatch(deleteClient({ id: entity.id as Client['id'] }, (res) => {
        portalMessage(res);
        onDelete();
      }))
    }
  }

  return (<>
    {
      confirmDeleteEntityModal && (
        <Confirm title={<p className="text-2xl">Estas seguro de eliminar a este {isDefendant ? 'demandado' : 'cliente'} ?</p>} content={
          <p className="mt-2">Eliminarlo puede significar no poder recuperarlo en un futuro</p>
        } onAccept={handleDelete} onDeny={() => setConfirmDeleteEntityModal(false)} />
      )
    }
    <Card className={`transform relative duration-300 cursor-pointer hover:scale-95	p-0 border-none relative ${bgColor}`}>
      <div className="w-full">
        {
          role === roles.ADMINISTRATOR && !isDefendant && (<div className="absolute right-2 z-20 top-2">
            <ToggleSwitch defaultValue={!entity?.disabled} onChange={onDisabledChange} />
          </div>)
        }
        <div onClick={onClick} className="break-words flex top-0 left-0 justify-between relative flex-col h-full text-gray-800 p-5" style={{ minHeight: 200 }}>
          <div className="flex">
            <div className="flex flex-col items-start " style={{ width: 'calc(100% - 35px)' }} >
              <p className="text-2xl truncate font-bold overflow-ellipsis w-full">{entity?.name || 'Sin información'}</p>
              <p className="overflow-ellipsis">{entity?.officialId || 'Sin información'}</p>
            </div>

          </div>
          <div style={{ maxWidth: 'calc(100% - 30px)' }} className="flex justify-between flex-row w-full">
            <div className="flex flex-col items-start mt-10  w-full">
              <div className="flex w-full">
                <p className="text-base font-bold mr-2">Número:</p>
                <p className="truncate overflow-ellipsis">{entity?.phone || 'Sin información'}</p>

              </div>
              <div className="flex w-full">
                <p className="text-base font-bold mr-2">Mail: </p>
                <p className="truncate overflow-ellipsis">{entity?.email || 'Sin información'}</p>
              </div>
              <div className="flex w-full">
                <p className="text-base font-bold mr-2">Dirección:</p>
                <p className="truncate overflow-ellipsis">{entity?.address || 'Sin información'}</p>
              </div>
            </div>
          </div>

          <BlueBrandIcon className="absolute right-0" style={{ zIndex: -1 }} />
        </div>
        <div className="flex justify-end items-end absolute right-2 bottom-4">
          <Dropdown trigger={(
            <Button className="bg-white text-primary rounded-full border-none" padding="px-1 py-3">
              <IoEllipsisVertical />
            </Button>
          )} dropdownList={{ style: { width: 100, bottom: '100%' } }}>
            <DropdownItem onClick={handleEdit}>Editar</DropdownItem>
            {
              (!isDefendant ? role === roles.ADMINISTRATOR : true) ? (
                <DropdownItem onClick={() => setConfirmDeleteEntityModal(true)}>Eliminar</DropdownItem>
              ) : (<></>)
            }
          </Dropdown>
        </div>
      </div>
    </Card>
  </>)
}