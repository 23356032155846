import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MemberNavbar from '../../components/MemberNavbar';

import * as processActions from '../../store/process/actions';

import CreateProcess from '../../components/CreateProcess';
import ProcessData from '../../components/ProcessData';
import LogsList from '../../components/LogsList';
import CreateLog from '../../components/CreateLog';
import CreatePayment from '../../components/CreatePayment';
import { ProcessType } from '../../store/process/types';
import { State } from '../../store/reducers';
import AttachmentsModal from '../../components/AttachmentsModal';
import PaymentsList from '../../components/PaymentsList';
// import LogList from '../../components/LogList'

export default function ProcessPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { gettedProcess: process } = useSelector((state: State): ProcessType => state.process);
  const dispatch = useDispatch();
  const [paymentsList, setPaymentsList] = useState<boolean>(false);

  const handleFindProcess = useCallback(function () {
    const parsedId = Number(id);
    if (parsedId)
      dispatch(processActions.getProcess({ id: parsedId }, ({ success }) => {
      }))
  }, [id, dispatch]);
  useEffect(() => {
    handleFindProcess()
    return () => {
      dispatch(processActions.setState({ gettedProcess: null }))
    }
  }, [dispatch, handleFindProcess, id]);



  return <div>
    {
      process && (
        <>
          <MemberNavbar />
          <CreateProcess onEdit={handleFindProcess} />
          <CreateLog onCreate={handleFindProcess} />
          <CreatePayment onCreate={handleFindProcess} />
          <AttachmentsModal client={process?.client || {}} />
          {
            Boolean(process?.id) && (
              <div className="flex flex-wrap items-stretch" style={{ minHeight: 'calc(100vh - 80px)' }}>
                <div className="w-full md:w-4/12 lg:w-3/12 bg-gray-50 shadow-lg">
                  <ProcessData setPaymentsList={setPaymentsList} handleReload={handleFindProcess} />
                </div>
                <div className="w-full md:w-8/12 lg:w-9/12">
                  {
                    paymentsList ? <PaymentsList handleReload={handleFindProcess} /> : (
                      <LogsList handleFindProcess={handleFindProcess} />
                    )
                  }
                </div>
              </div>
            )
          }
        </>
      )
    }
  </div>
}