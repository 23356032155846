import { combineReducers } from 'redux';

import { AuthType } from './auth/types';
import { ProcessType } from './process/types';
import { ClientType } from './client/types';
import { DefendantType } from './defendant/types';
import { LogType } from './log/types';
import { UserType } from './user/types';
import { PaymentType } from './payment/types';

import authReducer from './auth/reducer';
import processReducer from './process/reducer';
import clientReducer from './client/reducer';
import defendantReducer from './defendant/reducer';
import logReducer from './log/reducer';
import userReducer from './user/reducer';
import paymentReducer from './payment/reducer';


export interface State {
  auth: AuthType;
  process: ProcessType;
  client: ClientType;
  defendant: DefendantType;
  log: LogType;
  user: UserType;
  payment: PaymentType;
}

export default combineReducers({
  auth: authReducer,
  process: processReducer,
  client: clientReducer,
  defendant: defendantReducer,
  log: logReducer,
  user: userReducer,
  payment: paymentReducer
})