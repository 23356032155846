import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { State } from "../store/reducers";
import { AuthEntity, AuthType } from "../store/auth/types";

export function useUserRole() {
  const [rtRole, setRtRole] = useState<string>('');
  const authState = useSelector((state: State): AuthType => state?.auth || {});
  const entity = authState?.entity as AuthEntity;
  const { type = null, role = '' } = entity || {};
  useEffect(() => {
    if (type === 'user') setRtRole(role);
    else setRtRole('3'); // in client case
  }, [type, role]);

  return rtRole;
}
