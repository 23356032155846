import { User } from "../../interfaces/entities";

const types: Record<string, string> = {
  FETCH_LAWYERS_REQUEST: 'FETCH_LAWYERS_REQUEST',
  FETCH_LAWYERS_SUCCESS: 'FETCH_LAWYERS_SUCCESS',

  SET_LAWYER_MODAL: 'SET_LAWYER_MODAL',

  CREATE_LAWYER_REQUEST: 'CREATE_LAWYER_REQUEST',

  UPDATE_LAWYER_REQUEST: 'UPDATE_LAWYER_REQUEST'
}



export interface UserType {
  lawyers: User[];
  userModal: {
    active: boolean;
    edition: boolean;
    form: Partial<User>;
  }
}

export interface FetchLawyersAction {
  type: typeof types.FETCH_LAWYERS_REQUEST;
  payload: null;
}
export interface FetchLawyersSuccessAction {
  type: typeof types.FETCH_LAWYERS_REQUEST;
  payload: User[];
}


export interface SetLawyerModalAction {
  type: typeof types.SET_LAWYER_MODAL;
  payload: Partial<UserType['userModal']>;
}

export interface CreateLawyerAction {
  type: typeof types.CREATE_LAWYER_REQUEST;
  payload: { image?: File | undefined | null };
  cb: (res: { success: boolean; message: string }) => void;
}

export interface UpdateLawyerAction {
  type: typeof types.UPDATE_LAWYER_REQUEST,
  payload: { id: User['id'], fields: Partial<{ name: string; email: string; password: string; disabled: boolean; image: File; }> };
  cb: (res: { success: boolean; message: string }) => void;
}

export type Action =
  | FetchLawyersAction
  | FetchLawyersSuccessAction
  | SetLawyerModalAction
  | UpdateLawyerAction;

export default types; 