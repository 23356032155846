import axios from '../axiosInstance';
import { UserController } from './types';




export const User: UserController = {

  // * GET  
  fetchLawyers: () => axios.get('/user').then(res => res.data),

  // * PUT

  update: (request) => axios.put('/user', request).then(res => res.data)
}