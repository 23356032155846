
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Client } from '../../api';
import types, { ClientType, CreateClientAction, DeleteClientAction, UpdateClientAction } from './types';
import { fetchClients, fetchClientsSuccess, getClientResult, updateClientSuccess } from './actions';
import { FetchClientsAction, GetClientAction } from './types';
import { State } from '../reducers';


function* fetchClient({ payload, cb }: FetchClientsAction): SagaIterator {
  try {
    const { search, page } = payload;
    const { clients, success, pagesCount, clientsCount } = yield call(Client.fetchAll, { search, pageSize: 6, page });
    if (success) {
      yield put(fetchClientsSuccess({ clients, pagesCount, clientsCount }));
    }
  } catch (e) {
    console.log(e);
  }
  yield call(cb);
}

function* createClient({ cb }: CreateClientAction): SagaIterator {
  try {
    const { clientModal: { form } } = yield select((state: State): ClientType => state.client);
    const request = yield call(Client.create, form);
    yield call(cb, { message: 'Cliente creado correctamente', ...request });
    if (request.success) {
      yield put(fetchClients({ search: '', page: 0 }, () => { }))

    }
  } catch (e) {
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado al crear al cliente' });

  }
}

function* getClient({ cb, payload }: GetClientAction): SagaIterator {
  try {
    const { success, client, message = '' } = yield call(Client.getClient, payload);
    yield call(cb, { success, message });
    if (success)
      yield put(getClientResult(client));
  } catch (e) {
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado al buscar el cliente' });

    console.log(e);
  }
}

function* updateClient({ cb, payload }: UpdateClientAction): SagaIterator {
  try {
    const { success, client, message } = yield call(Client.update, payload);
    if (success)
      yield put(updateClientSuccess(payload));

    yield call(cb, { success, message, client });
  } catch (e) {
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado al buscar el cliente' })
    console.log(e);
  }
}


function* deleteClient({ payload, cb }: DeleteClientAction): SagaIterator {
  try {
    const res = yield call(Client.delete, payload);
    yield call(cb, res);

  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado' });

  }
}



export default function* clientSaga(): SagaIterator {
  yield takeLatest(types.FETCH_CLIENTS_REQUEST, fetchClient);
  yield takeLatest(types.CREATE_CLIENT_REQUEST, createClient);
  yield takeLatest(types.GET_CLIENT_REQUEST, getClient);
  yield takeLatest(types.UPDATE_CLIENT_REQUEST, updateClient);
  yield takeLatest(types.DELETE_CLIENT_REQUEST, deleteClient);
}