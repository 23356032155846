import React from 'react';
import { LabelProps } from './types';


export default function Label({ children, className = '', weight = 'font-medium', color = 'text-gray-500', ...extraProps }: LabelProps): JSX.Element {
  return (
    <label
      {...extraProps}
      className={[weight, 'text-base', 'mb-1', color, className].join(' ')}
    >
      {children}
    </label>
  )
}