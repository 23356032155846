import types, { LogType, Action, SetLogModalAction, SetAttachmentsModalAction } from './types';


const initialState: LogType = {
  logModal: {
    active: false,
    edition: false,
    form: {}
  },
  attachmentsModal: {
    active: false,
    private: false,
    log: {}
  }
}
export default function LogReducer(state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_LOG_MODAL: {
      const value = payload as SetLogModalAction['payload'];
      return {
        ...state,
        logModal: {
          active: value?.active ?? state.logModal.active,
          edition: value?.edition ?? state.logModal.edition,
          form: {
            ...state.logModal.form, ...(value?.form || {}),
          }
        }
      }
    }
    case types.SET_ATTACHMENTS_MODAL: {
      const value = payload as SetAttachmentsModalAction['payload'];
      return {
        ...state,
        attachmentsModal: {
          active: value?.active ?? state.attachmentsModal.active,
          private: value?.private ?? state.attachmentsModal.private,
          log: {
            ...state.attachmentsModal.log, ...(value?.log || {}),
          }
        }
      }
    }
    default:
      return state;
  }

}