import { useEffect, useState } from "react";

export function useUploadUrl(path: string = ''): string {
  const [userImage, setUserImage] = useState<string>('');

  useEffect(() => {
    setUserImage(`${process.env.REACT_APP_SERVER_URL}/${path?.split('\\').join('/')}`);
  }, [path]);

  return userImage;
}