import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiPencilLine } from 'react-icons/ri';

import { ClientDataProps } from './types';

import { ClientType } from '../../store/client/types';
import { State } from '../../store/reducers';
import { Input, Label } from '../ui/Input';
import Button from '../ui/Button';
import Select from '../ui/Select';
import { Process } from '../../interfaces/entities';
import { processStatus, processStatusColors } from '../../hooks';
import { Option } from '../ui/Select/types';
import { IoChevronBackOutline, IoRefresh } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { setClientModal } from '../../store/client/actions';
import LawyerAccess from '../LawyerAccess';



export default function ClientData({ onFiltersChange }: ClientDataProps): JSX.Element {
  const { gettedClient: client } = useSelector((state: State): ClientType => state.client);
  const history = useHistory();
  const dispatch = useDispatch();

  const statusOptions: Option<Process['status']>[] = [
    {
      text: (
        <div className="w-full flex justify-between">
          <span className="font-bold mr-2 truncate" style={{ color: '#DB2777' }}>Todos los estados</span>
          <div className="w-1"></div>
          <span className="text-right flex items-center font-bold" style={{ color: '#DB2777' }}><IoRefresh /></span>
        </div>
      ),
      value: null
    },
    ...Object.keys(processStatus).map((status: any) => ({
      text: (
        <div className="w-full flex justify-between">
          <span className="font-bold mr-2 truncate" style={{ color: processStatusColors[status] }}>{processStatus[status as number]}</span>
          <div className="w-1"></div>
          <span className="text-right font-bold" style={{ color: processStatusColors[status] }}>{status}%</span>
        </div>
      ),
      value: status
    }))];

  function handleStatusChange(status: Process['status']) {
    onFiltersChange({ status })
  }
  function handleInternalIdChange(e: ChangeEvent<{ value: string }>) {
    e.persist();
    e.preventDefault();
    onFiltersChange({ internalId: e.target.value })
  }

  function handleOpenEditClient() {
    dispatch(setClientModal({ active: true, edition: true, form: { ...client } }));
  }


  return (
    <>
      <div className="flex flex-col py-10 pl-5 pr-5 items-start text-left">
        <div className="w-full text-5xl wrap ">
          <h1 className="font-bold text-3xl text-primary w-full break-word m-0 pb-2">
            {client?.name}
          </h1>
        </div>
        <div className="flex flex-wrap mt-5">
          <Label weight="font-black" className="text-lg" color="text-black">RUC / CI:</Label>
          <p className="ml-2 text-lg">{client?.officialId}</p>
        </div>
        <div className="flex flex-wrap items-center justify-between mt-5 w-full">
          <Label weight="font-black" className="text-xl" color="text-black">
            Información
          </Label>

          <LawyerAccess>
            <Button onClick={handleOpenEditClient}>
              <RiPencilLine size="20px" />
            </Button>
          </LawyerAccess>
        </div>
        {client?.legalPerson && client?.generalManager && (
          <div className="flex flex-col flex-wrap items-start mt-3 w-full">
            <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
              Gerente General:
          </Label>
            <Label weight="font-normal" className="text-lg p-0 m-0 break-all" color="text-black">
              {client.generalManager}
            </Label>
          </div>
        )}
        <div className="flex flex-col flex-wrap items-start mt-3 w-full">
          <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
            Dirección:
          </Label>
          <Label weight="font-normal" className="text-lg p-0 m-0 break-all" color="text-black">
            {client?.address || 'Sin información'}
          </Label>
        </div>
        <div className="flex flex-col flex-wrap items-start mt-3 w-full">
          <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
            Número:
          </Label>
          <Label weight="font-normal" className="text-lg p-0 m-0 break-all" color="text-black">
            {client?.phone || 'Sin información'}
          </Label>
        </div>
        <div className="flex flex-col flex-wrap items-start mt-3 w-full">
          <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
            Mail:
          </Label>
          <Label weight="font-normal" className="text-lg p-0 m-0 break-all" color="text-black">
            {client?.email || 'Sin información'}
          </Label>
        </div>
        <div className="mt-5 w-full">
          <Label weight="font-black" className="text-2xl" color="text-black">
            Filtros de búsqueda:
        </Label>
        </div>
        <div className="flex flex-col flex-wrap items-start mt-5 w-full">
          <Label weight="font-medium" className="text-xl p-0 m-0" color="text-black">
            Número de proceso:
        </Label>
          <Input onChange={handleInternalIdChange} placeholder="Ingrese el número de un proceso" type="text" />
        </div>
        <div className="flex flex-col flex-wrap items-start mt-5 w-full">
          <Label weight="font-medium" className="text-xl p-0 m-0" color="text-black">
            Status:
        </Label>
          <Select<Process['status']> position="top" options={statusOptions} defaultValue={statusOptions[0]} onChange={handleStatusChange} />
        </div>

        <LawyerAccess>
          <div className="flex flex-col flex-wrap items-start mt-20 w-full">
            <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15" onClick={() => history.push('/member')}>
              <IoChevronBackOutline size="30px" />
            </Button>
          </div>
        </LawyerAccess>
      </div>
    </>
  )
}
