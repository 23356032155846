import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import rootSaga from './sagas';
import rootReducer from './reducers';

const w: any = window as Window
const devtools = w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (
  process.env.NODE_ENV !== 'production' &&
  devtools && typeof window === 'object'
) ? devtools({}) : compose

const persistConfig = {
  key: 'persistent_store',
  storage: storage,
  whitelist: ['auth'] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);




const store: Store<typeof rootReducer> = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga)


export { store, persistor };