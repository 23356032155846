import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useUserRole } from '../hooks/useUserRole';

import { State } from '../store/reducers';
import { PrivateRouteProps } from './types';

export const PrivateRouteHOC = (component: React.ElementType, roles = ['1', '2', '3']): React.FC<RouteComponentProps> =>
  ({ ...props }: RouteComponentProps): JSX.Element => <PrivateRoute Component={component} roles={roles} {...props} />


export default function PrivateRoute({ Component, roles = ['1', '2', '3'], ...props }: PrivateRouteProps): JSX.Element {
  const { isLoggedIn } = useSelector((state: State) => state.auth);
  const history = useHistory();

  const role = useUserRole();


  useEffect(() => {
    if (isLoggedIn === false) history.push('/')
  }, [isLoggedIn, history]);

  return isLoggedIn && roles.includes(role) ? <Component {...props} /> : <></>;
}