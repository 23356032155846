import axios from '../axiosInstance';
import { PaymentController } from './types';




export const Payment: PaymentController = {

  // * POST  
  create: (request) => axios.post('/payment', request).then(res => res.data),

  // * PUT

  update: (request) => axios.put('/payment', request).then(res => res.data),

  // * DELETE
  delete: (request) => axios.delete('/payment', { data: request }).then(res => res.data)
}