import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { Log } from '../../api/log';
import { State } from '../reducers';

import types, { CreateLogAction, DeleteAttachmentAction, DeleteLogAction, DownloadAttachmentsAction, LogType, UpdateLogAction } from './types';
import { setLogModal } from './actions';

function* createLog({ payload, cb }: CreateLogAction): SagaIterator {
  try {
    const formData = new FormData();

    payload.private_files?.map((file) => formData.append('private_attachments', file));
    payload.public_files?.map((file) => formData.append('public_attachments', file));

    const { content, date, processId, internalId } = yield select((state: State): LogType['logModal']['form'] => state.log.logModal.form)

    formData.append('content', content ?? '');
    formData.append('date', date ? date : moment(new Date()).format('YYYY-MM-DD'));
    if (processId) formData.append('processId', processId);
    if (internalId) formData.append('internalId', internalId);

    const res = yield call(Log.create, formData);

    yield call(cb, res);

    yield put(setLogModal({ form: { content: '', date: '', internalId: undefined, processId: undefined } }))

  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });
  }
}

function* deleteLog({ payload, cb }: DeleteLogAction): SagaIterator {
  try {
    const res = yield call(Log.delete, payload);
    yield call(cb, res);


  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });

  }
}
function* updateLog({ payload, cb }: UpdateLogAction): SagaIterator {
  try {
    const res = yield call(Log.update, payload);
    yield call(cb, res);

    yield put(setLogModal({ form: { content: '', date: '', internalId: undefined, processId: undefined } }))


  }
  catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });

  }
}
function* deleteAttachment({ payload, cb }: DeleteAttachmentAction): SagaIterator {
  try {
    const res = yield call(Log.deleteAttachment, payload);
    yield call(cb, res);


  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });

  }
}

function* downloadAttachments({ payload }: DownloadAttachmentsAction): SagaIterator {
  try {
    const file = yield call(Log.downloadAttachmentsZip, { id: payload.id, private: payload.private ? 1 : 0 });
    saveAs(file, 'Adjuntos.zip');
    console.log(file);
  } catch (e) {
    console.log(e);
  }
}

export default function* LogSaga(): SagaIterator {
  yield takeLatest(types.CREATE_LOG_REQUEST, createLog);
  yield takeLatest(types.DELETE_LOG_REQUEST, deleteLog);
  yield takeLatest(types.UPDATE_LOG_REQUEST, updateLog);
  yield takeLatest(types.DELETE_ATTACHMENT_REQUEST, deleteAttachment);
  yield takeLatest(types.DOWNLOAD_ATTACHMENTS_REQUEST, downloadAttachments);
}