import types, { CreateLawyerAction, FetchLawyersAction, FetchLawyersSuccessAction, SetLawyerModalAction, UpdateLawyerAction } from './types';



export const fetchLawyers = (): FetchLawyersAction => ({
  type: types.FETCH_LAWYERS_REQUEST,
  payload: null
});


export const fetchLawyersSucces = (payload: FetchLawyersSuccessAction['payload']): FetchLawyersSuccessAction => ({
  type: types.FETCH_LAWYERS_SUCCESS,
  payload
});

export const updateLawyer = (payload: UpdateLawyerAction['payload'], cb: UpdateLawyerAction['cb']): UpdateLawyerAction => ({
  type: types.UPDATE_LAWYER_REQUEST,
  payload,
  cb
})

export const setLawyerModal = (payload: SetLawyerModalAction['payload']): SetLawyerModalAction => ({
  type: types.SET_LAWYER_MODAL,
  payload
});

export const createLawyer = (payload: CreateLawyerAction['payload'], cb: CreateLawyerAction['cb']): CreateLawyerAction => ({
  type: types.CREATE_LAWYER_REQUEST,
  payload,
  cb
})