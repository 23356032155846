import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import MemberNavbar from '../../components/MemberNavbar';

import * as clientActions from '../../store/client/actions';

import ClientData from '../../components/ClientData';
import ProcessList from '../../components/ProcessList';

import { FindProcessFilters } from './types';
import CreateProcess from '../../components/CreateProcess';
import CreateClient from '../../components/CreateClient';
import CreateLog from '../../components/CreateLog';


export default function ClientPage(): JSX.Element {
  const [filters, setFilters] = useState<FindProcessFilters>({ internalId: '', status: null });
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const handleFindClient = useCallback(function () {
    const parsedId = Number(id);
    if (parsedId)
      dispatch(clientActions.getClient({ id: parsedId }, ({ success }) => {
      }))
  }, [id, dispatch]);
  useEffect(() => {
    handleFindClient()
    return () => {
      dispatch(clientActions.setState({ gettedClient: null }))
    }
  }, [dispatch, handleFindClient, id]);

  function handleFilter(filters: Partial<FindProcessFilters>) {
    setFilters((prev: Partial<FindProcessFilters>) => ({ ...prev, ...filters }))
  }

  return <div>
    <MemberNavbar />
    <CreateProcess onEdit={handleFindClient} />
    <CreateClient onEdit={handleFindClient} />
    <CreateLog />

    <div className="flex flex-wrap items-stretch" style={{ minHeight: 'calc(100vh - 80px)' }}>
      <div className="w-full md:w-4/12 lg:w-3/12 bg-gray-50 shadow-lg">
        <ClientData onFiltersChange={handleFilter} />
      </div>
      <div className="w-full md:w-8/12 lg:w-9/12">
        <ProcessList handleReload={handleFindClient} filters={filters} />
      </div>
    </div>

  </div>
}