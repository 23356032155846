import { useEffect, useState } from 'react';


export function useFormattedAmount(amount: string | number): string {
  const [formattedAmount, setFormattedAmount] = useState<string>('');
  useEffect(() => {
    setFormattedAmount(new Intl.NumberFormat("de-DE").format(Number(amount)));
  }, [amount]);

  return formattedAmount;
}