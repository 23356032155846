import { SignInResponse } from "../../api/auth/types";
import types, { SetAuthStatePayload, SignInAction, SignInPayload, SignInSuccessAction, SignInSuccessPayload } from "./types";



export const signIn = (payload: SignInPayload, cb: (request: SignInResponse) => void): SignInAction => ({
  type: types.SIGN_IN_REQUEST,
  payload,
  cb
});
export const signInSuccess = (payload: SignInSuccessPayload): SignInSuccessAction => ({
  type: types.SIGN_IN_SUCCESS,
  payload
})


export const verifySession = () => ({
  type: types.VERIFY_SESSION,
  payload: null
});


export const setState = (payload: SetAuthStatePayload) => ({
  type: types.SET_AUTH_STATE,
  payload
})