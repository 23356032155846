import React from 'react';
import { ButtonProps } from './types';


export default function Button({ children, className = '', border = 'border', padding = 'p-3', shadow = 'shadow-sm', ...extraProps }: ButtonProps): JSX.Element {
  return (
    <button
      className={[
        'outline-none', 'focus:outline-none',
        'rounded-md', border, shadow, padding, className
      ].join(' ')}
      {...extraProps}
    >
      {children}
    </button>
  )
}