import { CreatePaymentResponse, DeletePaymentResponse, UpdatePaymentResponse } from "../../api/payment/types";
import { Payment } from "../../interfaces/entities";

const types: Record<string, string> = {
  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',

  SET_PAYMENT_MODAL: 'SET_PAYMENT_MODAL',

  DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',

  UPDATE_PAYMENT_REQUEST: 'UPDATE_PAYMENT_REQUEST',
}



export interface PaymentType {
  paymentModal: {
    active: boolean;
    edition: boolean;
    form: Partial<Payment> & { internalId?: number; };
  },
}

export interface CreatePaymentAction {
  type: typeof types.CREATE_PAYMENT_REQUEST;
  cb: (res: CreatePaymentResponse) => void;
  payload: { attachment?: File };
}

export interface SetPaymentModalAction {
  type: typeof types.SET_PAYMENT_MODAL;
  payload: Partial<PaymentType['paymentModal']>;
}

export interface DeletePaymentAction {
  type: typeof types.DELETE_PAYMENT_REQUEST;
  payload: { id: Payment['id'] };
  cb: (res: DeletePaymentResponse) => void;
}

export interface UpdatePaymentAction {
  type: typeof types.UPDATE_PAYMENT_REQUEST,
  payload: { id: Payment['id'], fields: Partial<Payment> & { attachment?: File } };
  cb: (res: UpdatePaymentResponse) => void;
}



export type Action =
  | CreatePaymentAction
  | SetPaymentModalAction
  | DeletePaymentAction
  | UpdatePaymentAction;

export default types; 