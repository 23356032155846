import React from 'react';
import { DropdownItemProps } from './types';


export function DropdownItem({ children, onHoverClass = 'hover:bg-gray-200 duration-200', textAlign = 'text-left', padding = 'p-2', className, ...rest }: DropdownItemProps) {
  return (
    <div className={
      ['cursor-pointer', onHoverClass, textAlign, padding, className].join(' ')
    }  {...rest}>
      {children}
    </div>
  )
}