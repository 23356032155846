import Axios from 'axios'

const serverUrl = process.env.REACT_APP_SERVER_URL || ''


const axios = Axios.create({
  baseURL: serverUrl
})

const setAccessToken = (accessToken: string): void => {
  localStorage.setItem('access_token', accessToken)
}

const clearAccessToken = (): void => {
  localStorage.removeItem('access_token')
}

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

const isAlreadyLogged = (): boolean => {
  const token = localStorage.getItem('access_token')
  return Boolean(token && parseJwt(token).name);
}

axios.interceptors.request.use(async (req) => {
  const token = localStorage.getItem('access_token')
  if (token && token !== 'undefined') {
    req.headers.Authorization = `Bearer ${token}`
  }
  return req
})

export default axios
export {
  setAccessToken,
  clearAccessToken,
  isAlreadyLogged
}
