import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IoCheckmarkOutline, IoCloseCircleSharp, IoImageOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { usePortal } from '../../hooks';
import { State } from '../../store/reducers';
import { createLawyer, setLawyerModal, updateLawyer } from '../../store/user/actions';
import { UserType } from '../../store/user/types';
import Button from '../ui/Button';
import { Input, InputGroup, Label } from '../ui/Input';
import Message from '../ui/Message';
import Modal from '../ui/Modal';
import Temporal from '../ui/Temporal';
import CreateUserModalWrapper from './styles';



export default function CreateUser(): JSX.Element {
  const { userModal: { active, form, edition } } = useSelector((state: State): UserType => state.user);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [image, setImage] = useState<File | undefined>();
  const [imageError, setImageError] = useState<string | undefined>();
  const dispatch = useDispatch();
  const inputImage = useRef<any>();
  const Portal = usePortal();

  function handleClose(): void {
    const resettedForm = _.mapValues(form, () => undefined);
    dispatch(setLawyerModal({ active: false, edition: false, form: resettedForm }));
  }

  function handleSubmit(e: FormEvent) {
    e.persist();
    e.preventDefault();
    const portalMessage = ({ success, message }: { success: boolean, message: string | null }) => Portal.add(
      <Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium shadow-xl border-2 border border-gray-200 rounded-lg  text-center md:text-left"
          color="bg-white"
        >
          {!success ? `${message}` : `Se ha ${edition ? 'editado' : 'creado'} el abogado correctamente`}
        </Message>
      </Temporal>
    );
    if (!edition)
      dispatch(createLawyer({ image }, (res) => {
        portalMessage(res);
        handleClose();
      }));
    else {
      let { id, image: img, ...fields } = form;
      const fieldsNw: any = { ...fields };
      if (image) fieldsNw.image = image;
      if (!editPassword) delete fieldsNw?.password;

      dispatch(updateLawyer({ id: id as number, fields: fieldsNw }, (res) => {
        portalMessage(res);
        handleClose();
      }))
    }
  }
  function handleInputChange(e: ChangeEvent<{ value: string; name: string | undefined }>) {
    e.persist();
    dispatch(setLawyerModal({ form: { [e.target.name as string]: e.target.value } }))

  }
  function handleSetImage(e: ChangeEvent<{ files: FileList | null }>) {
    e.persist();
    const avExtensions: string[] = ['jpg', 'png', 'svg', 'jpeg'];
    const image: File | undefined = e?.target?.files ? e?.target?.files[0] : undefined;

    if (image) {
      const extensions = image?.name?.split('.');
      const ext = extensions[extensions.length - 1];
      if (avExtensions.includes(ext)) {
        setImage(image);
        setImageError(undefined);
      } else
        setImageError(`Las extensiones permitidas son las siguientes: ${avExtensions.join(', ')}`)
    }

  }
  useEffect(() => {
    return () => {
      setImage(undefined);
      setImageError(undefined);
    }
  }, [active])
  return (
    <CreateUserModalWrapper>
      <Modal isActive={active} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <p className="text-2xl mb-3">Agregar abogado</p>
          <InputGroup className="mb-4">
            <Label color="text-gray-600" className="text-base">Nombre:</Label>
            <Input autoFocus onChange={handleInputChange} defaultValue={form?.name || ''} name="name" required type="text" placeholder="Ingrese un Nombre" />
          </InputGroup>
          <InputGroup className="mb-4">
            <Label color="text-gray-600" className="text-base">Usuario:</Label>
            <Input onChange={handleInputChange} defaultValue={form?.email || ''} name="email" required type="text" placeholder="Ingrese un usuario (formato de mail)" />
          </InputGroup>

          {
            edition ? (
              <>
                <label className="inline-flex items-center mt-3">
                  <input type="checkbox" className="form-checkbox h-5 w-5 text-pink-600" onChange={(e) => setEditPassword(e.target.checked)} /><span className="ml-2 text-gray-700">Deseas modificar la contraseña?</span>
                </label>
                {editPassword && (
                  <InputGroup className="mb-4">
                    <Label color="text-gray-600" className="text-base">Contraseña:</Label>
                    <Input onChange={handleInputChange} minLength={5} name="password" required type="password" placeholder="Ingrese una contraseña (mayor a 5 caracteres)" />
                  </InputGroup>
                )}
              </>
            ) : (<InputGroup className="mb-4">
              <Label color="text-gray-600" className="text-base">Contraseña:</Label>
              <Input onChange={handleInputChange} minLength={5} name="password" required type="password" placeholder="Ingrese una contraseña (mayor a 5 caracteres)" />
            </InputGroup>)
          }
          {
            imageError && (
              <Message rounded className="mt-5 mb-5" closable={false} icon={<BiInfoCircle size="20px" className="tetx-blue" />}>
                {imageError}
              </Message>
            )
          }
          <div onClick={() => inputImage.current.click()} className="cursor-pointer flex justify-center text-gray-700 items-center h-28 w-full border-2 border-dashed border-gray-100 rounded-xl">

            {image ? (
              <span className="bg-green bg-opacity-30 px-12 rounded-xl py-3">
                {image?.name}
              </span>
            ) : <IoImageOutline size="40px" />}
            <input ref={inputImage} type="file" style={{ display: 'none' }} onChange={handleSetImage} />
          </div>
          <Button className="bg-primary text-white w-full mt-5">
            {edition ? 'Guardar cambios' : 'Crear abogado'}
          </Button>
        </form>
      </Modal>
    </CreateUserModalWrapper>
  )
}