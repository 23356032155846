import { GetProcessResponse, UpdateProcessResponse } from "../../api/process/types";
import { Process, ToDo } from "../../interfaces/entities";
import { SetToDoResponse } from '../../api/toDo/types';

const types: Record<string, string> = {
  FETCH_PROCESSES_REQUEST: 'FETCH_PROCESSES_REQUEST',
  SET_PROCESS_MODAL: 'SET_PROCESS_MODAL',

  // create process
  CREATE_PROCESS_REQUEST: 'CREATE_PROCESS_REQUEST',
  CREATE_PROCESS_SUCCESS: 'CREATE_PROCESS_SUCCESS',
  CREATE_PROCESS_FAILED: 'CREATE_PROCESS_FAILED',

  // update process
  UPDATE_PROCESS_REQUEST: 'UPDATE_PROCESS_REQUEST',
  UPDATE_PROCESS_SUCCESS: 'UPDATE_PROCESS_SUCCESS',

  GET_PROCESS_REQUEST: 'GET_PROCESS_REQUEST',
  GET_PROCESS_SUCCESS: 'GET_PROCESS_SUCCESS',
  // SET PROCESS STATE
  SET_PROCESS_STATE: 'SET_PROCESS_STATE',

  SET_TODO_REQUEST: 'SET_TODO_REQUEST'
}


export interface ProcessModalForm extends Partial<Process> {
  defendantName?: string;
  defendantOfficialId?: string;
}

export interface ProcessModal {
  active: boolean;
  edition: boolean;
  form: ProcessModalForm;
}
export interface ProcessType {
  processes: Process[] | null;
  gettedProcess: Partial<Process> | null;
  pageSize: number;
  processModal: ProcessModal
}


//Payload
export type FetchProcessesPayload = string;

// Actions
export interface FetchProcessesAction {
  type: typeof types.FETCH_PROCESSES_REQUEST,
  payload: FetchProcessesPayload
}

export interface SetProcessModalAction {
  type: typeof types.SET_PROCESS_MODAL
  payload: Partial<ProcessModal>
}

export interface CreateProcessAction {
  type: typeof types.CREATE_PROCESS_REQUEST;
  payload: null;
  cb: (res: { success: boolean, message: string | null; }) => void
}

export interface CreateProcessSuccessAction {
  type: typeof types.CREATE_PROCESS_SUCCESS;
  payload: null;
}

export interface CreateProcessFailedAction {
  type: typeof types.CREATE_PROCESS_FAILED;
  payload: null;
}

export interface UpdateProcessAction {
  type: typeof types.UPDATE_PROCESS_REQUEST;
  payload: { id: Process['id'], fields: Partial<Process> };
  cb: (res: UpdateProcessResponse) => void;
}
export interface UpdateProcessSuccessAction {
  type: typeof types.UPDATE_PROCESS_SUCCESS;
  payload: UpdateProcessAction['payload'];
}

export interface GetProcessAction {
  type: typeof types.GET_PROCESS_REQUEST,
  cb: (res: GetProcessResponse) => void;
  payload: { id: Process['id'] };
}

export interface GetProcessSuccessAction {
  type: typeof types.GET_PROCESS_SUCCESS;
  payload: Partial<Process>;
}

export interface SetProcessStateAction {
  type: typeof types.SET_PROCESS_STATE,
  payload: Partial<ProcessType>
}

export interface SetToDoAction {
  type: typeof types.SET_TODO_REQUEST;
  payload: Partial<ToDo>;
  cb: (res: SetToDoResponse) => void;
}

export type Action =
  | FetchProcessesAction
  | SetProcessModalAction
  | CreateProcessAction
  | CreateProcessSuccessAction
  | CreateProcessFailedAction
  | UpdateProcessAction
  | UpdateProcessSuccessAction
  | GetProcessAction
  | GetProcessSuccessAction
  | SetProcessStateAction
  | SetToDoAction;

export default types;
