import React from 'react';
import Spinner from '../Spinner';
import { InputProps } from './types';


export default function Input({ className = '', loading = false, spinner = 'text-primary', multiline = false, ...extraProps }: InputProps): JSX.Element {
  return (
    <div className="w-full relative flex items-center">
      { multiline ? <textarea
        {...extraProps}
        className={[
          'w-full', 'outline-none', 'p-2',
          'shadow-sm', 'hover:shadow-md', 'rounded-md',
          'border', 'border-gray-200', 'duration-200', className].join(' ')}
      /> : (<input
        {...extraProps}
        className={[
          'w-full', 'outline-none', 'p-2',
          'shadow-sm', 'hover:shadow-md', 'rounded-md',
          'border', 'border-gray-200', 'duration-200', className].join(' ')}
      />)
      }
      {
        loading && (
          <Spinner className={`absolute right-0 -ml-1 mr-3 h-5 w-5 ${spinner}`} />
        )
      }
    </div>
  )
}