import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { MessageProps } from './types';

export default function Message({
  children,
  icon = <></>,
  color = 'bg-gray-50',
  textColor = 'text-gray-500',
  className = '',
  rounded = false,
  closable = true,
  container = { className: '' },
  onClose = () => { },
}: MessageProps): JSX.Element {
  const [closed, setClosed] = useState<boolean>(false);
  function handleClose() {
    setClosed(true);
    onClose();
  }
  const { className: containerClassName, ...containerExtraProps } = container;

  return (
    <>
      {!closed && (
        <div className={[containerClassName].join(' ')} {...containerExtraProps}>
          <div className={[
            'p-4', rounded && 'rounded-lg',
            'flex', 'items-center', 'relative',
            'flex-1', color, textColor,
            'w-full', 'flex-col', 'md:flex-row', className
          ].join(' ')}>
            <div className="pl-5 pr-5"> {icon} </div>
            <div className="p-2" style={{ flexBasis: '100%' }}>
              {children}
            </div>
            {closable &&
              (
                <div onClick={handleClose} className="absolute top-2 right-2 cursor-pointer">
                  <IoClose size="20px" />
                </div>
              )
            }
          </div>
        </div>
      )}
    </>
  )
}