import { SignInResponse } from "../../api/auth/types";
import { User, Client } from "../../interfaces/entities";



export type AuthEntity = User & {
  type?: string;

} | Client & {
  type?: string;
  role?: "1" | "2" | "3";
  image?: string | null;

}

export type AuthType = {
  isLoggedIn: boolean | null;
  entity: AuthEntity | null;
}

const types: Record<string, string> = {
  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',

  VERIFY_SESSION: 'VERIFY_SESSION',
  SET_AUTH_STATE: 'SET_AUTH_STATE'
};

// Payloads
export interface SignInPayload {
  email: string;
  password: string;
  type: 'user' | 'client';
}
export type SignInSuccessPayload = AuthEntity;

export type SetAuthStatePayload = Partial<AuthType>;


//Actions

export interface SignInAction {
  type: typeof types.SIGN_IN_REQUEST;
  payload: SignInPayload;
  cb: (request: SignInResponse) => void
}
export interface SignInSuccessAction {
  type: typeof types.SIGN_IN_SUCCESS;
  payload: SignInSuccessPayload
}

export interface VerifySessionAction {
  type: typeof types.VERIFY_SESSION;
  payload: null
}

export interface SetAuthStateAction {
  type: typeof types.SET_AUTH_STATE;
  payload: SetAuthStatePayload
}
export type Action =
  SignInAction
  | SignInSuccessAction
  | VerifySessionAction
  | SetAuthStateAction

export default types;
