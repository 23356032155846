import types, { CreateDefendantAction, DefendantModalAction, DeleteDefendantAction, FetchDefendantsAction, FetchDefendantsSuccessAction, UpdateDefendantAction, UpdateDefendantSuccessAction } from './types';



export const fetchDefendants = (payload: FetchDefendantsAction['payload'], cb: FetchDefendantsAction['cb']): FetchDefendantsAction => ({
  type: types.FETCH_DEFENDANTS_REQUEST,
  payload,
  cb
})
export const fetchDefendantsSuccess = (payload: FetchDefendantsSuccessAction['payload']): FetchDefendantsSuccessAction => ({
  type: types.FETCH_DEFENDANTS_SUCCESS,
  payload
})


export const setDefendantModal = (payload: Partial<DefendantModalAction['payload']>): DefendantModalAction => ({
  type: types.SET_DEFENDANT_MODAL,
  payload
})

export const createDefendant = (cb: CreateDefendantAction['cb']): CreateDefendantAction => ({
  type: types.CREATE_DEFENDANT_REQUEST,
  cb,
  payload: null
})



export const updateDefendant = (payload: UpdateDefendantAction['payload'], cb: UpdateDefendantAction['cb']): UpdateDefendantAction => ({
  type: types.UPDATE_DEFENDANT_REQUEST,
  payload,
  cb
})


export const updateDefendantSuccess = (payload: UpdateDefendantSuccessAction['payload']): UpdateDefendantSuccessAction => ({
  type: types.UPDATE_DEFENDANT_SUCCESS,
  payload
})


export const deleteDefendant = (payload: DeleteDefendantAction['payload'], cb: DeleteDefendantAction['cb']): DeleteDefendantAction => ({
  type: types.DELETE_DEFENDANT_REQUEST,
  payload,
  cb
}) 