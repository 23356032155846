import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { IoAddSharp, IoRefresh } from 'react-icons/io5';

import { ProcessType } from '../../store/process/types';
import { State } from '../../store/reducers';

import { ReactComponent as LogIllustration } from '../../assets/images/logs-illustration.svg';

import { Log } from '../../interfaces/entities';
import Pagination from '../ui/Pagination';
import Button from '../ui/Button';

import LogCard from '../LogCard';
import { setLogModal } from '../../store/log/actions';
import { HiArrowNarrowDown, HiArrowNarrowUp } from 'react-icons/hi';
import { LogsListProps } from './types';
import { useEntityFormattedName } from '../../hooks';
import ProcessToDo from '../ProcessToDo';
import LawyerAccess from '../LawyerAccess';

export default function LogsList({ handleFindProcess }: LogsListProps) {
  const { gettedProcess: process } = useSelector((state: State): ProcessType => state.process);
  const [logs, setLogs] = useState<Partial<Log>[][]>([]);
  const paginationState = useState<number>(0);
  const [orderByDesc, setOrderByDesc] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { prefix, formattedName } = useEntityFormattedName();
  const setPagination = paginationState[1];

  function openCreateLogModal() {
    dispatch(setLogModal({ active: true, form: { internalId: process?.internalId, processId: process?.id } }));

  }

  useEffect(() => {
    let sortedLogs = process?.logs || [];
    sortedLogs.sort((a, b) => {
      const creationTimeA = new Date(a.createdAt as string).getTime();
      const creationTimeB = new Date(b.createdAt as string).getTime();
      return orderByDesc ? creationTimeB - creationTimeA : creationTimeA - creationTimeB;
    })
    setLogs(_.chunk(sortedLogs, 5));
    setPagination(0);
  }, [process, orderByDesc, setPagination]);


  return (
    <div className="p-2 md:p-10">
      <div className="w-full flex mb-5 justify-between items-center flex-col md:flex-row">
        <h3 className="text-2xl font-bold text-center md:text-left mb-5 md:mb-0">
          Pendiente
        </h3>
        <div className="flex">
          <Button className="flex items-center bg-gray-50 mr-5" shadow="" border="" onClick={() => setOrderByDesc(prev => !prev)}>
            {
              !orderByDesc ? (<>
                <HiArrowNarrowUp size="22px" className="mr-3" />
                Más Recientes
              </>) : (<>
                  <HiArrowNarrowDown size="22px" className="mr-3" />
                  Más Antiguas
                </>)
            }
          </Button>
          <LawyerAccess>
            <Button onClick={openCreateLogModal} className="bg-primary text-white"><IoAddSharp size="25px" /></Button>
          </LawyerAccess>

        </div>
      </div>
      {
        <ProcessToDo />
      }
      {
        logs[paginationState[0]]?.map((log, index) => (
          <React.Fragment key={index}>
            <LogCard handleReload={handleFindProcess} {...log} />
          </React.Fragment>
        ))
      }
      {logs.length > 1 && (
        <div className="justify-between flex w-full mt-10">
          <div className="w-min">
            <Pagination pagesCount={logs?.length || 0} state={paginationState} />
          </div>
          <div>
            <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15" onClick={handleFindProcess}>
              <IoRefresh />
            </Button>
          </div>
        </div>
      )}

      {
        logs?.length === 0 && (
          <LawyerAccess>
            <section className="flex overflow-auto flex-col justify-center items-center" style={{ minHeight: 'calc(100vh - 80px)' }}>
              <>
                <h1 className="text-4xl	text-primary font-bold mt-2 text-center">{prefix}. {formattedName}</h1>

                <p className="font-black text-xl sm:text-2xl mt-5 text-gray-600 mb-10 sm:mb-5 text-center">Agregue a su primer actividad</p>
                <LogIllustration style={{ minWidth: '300px', width: '30%' }} />
                <Button onClick={openCreateLogModal} className="mt-10 bg-primary text-white pr-10 pl-10" >Crear Actividad</Button>
              </>
            </section>
          </LawyerAccess>
        )
      }
    </div>
  );
}