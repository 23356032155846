import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { IoCheckmarkOutline, IoCloseCircleSharp, IoEye, IoTrashBinOutline } from 'react-icons/io5';
import { HiOutlineDownload } from 'react-icons/hi';


import axiosInstance from '../../api/axiosInstance';
import Card from '../ui/Card';
import { AttachmentCardProps } from './types';
import Button from '../ui/Button';
import { usePortal, useUploadUrl } from '../../hooks';
import { deleteAttachment } from '../../store/log/actions';
import Temporal from '../ui/Temporal';
import Message from '../ui/Message';
import LawyerAccess from '../LawyerAccess';

export default function AttachmentCard({ id, url, createdAt, extension, private: privateLog }: AttachmentCardProps) {
  const [name, setName] = useState<string>('');
  const fileUrl = useUploadUrl(url);
  const dispatch = useDispatch();
  const Portal = usePortal();
  const [deleted, setDeleted] = useState<boolean>(false);
  async function handleDownloadAttachment() {
    const response = await axiosInstance.get('/download?path=' + url?.split('\\').join('/'), {
      responseType: 'blob',
    });
    saveAs(new Blob([response.data], {
      type: `${extension}`
    }));
  }
  function handleDeleteAttachment() {
    const portalMessage = ({ success, message }: { success: boolean; message: string; }) =>
      Portal.add(<Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium border-2 border border-gray-200 shadow-xl rounded-lg text-center md:text-left"
          color="bg-white"

        >
          {success ? `Se ha eliminado el adjunto correctamente` : message}
        </Message>
      </Temporal>)
    dispatch(deleteAttachment({ id: id as number }, (res) => {
      portalMessage(res);
      setDeleted(true);
    }));
  }
  useEffect(() => {
    const name = ((url || '')?.split('_')[2]?.split('_S8')?.join(' ')?.split('.')[0]) || 'Archivo';

    setName(name)
  }, [url]);
  return (
    <>
      {!deleted && (<Card className="mt-2 border-2 border-primary" >
        <div className="w-full px-5 py-2 flex flex-wrap justify-between">
          <div className="flex justify-center flex-col">
            <div className="flex items-center">
              <p className="text-xl font-bold">{name.substr(0, 10).toUpperCase()} </p>
              <div className=" ml-5 bg-skyblue h-7 text-xs flex items-center justify-center text-center rounded-full px-5 text-blue-900">
                {moment.utc(createdAt).format('DD/MM/YYYY')}
              </div>
            </div>
            <div>
              <p className={`text-lg ${privateLog ? 'text-red' : 'text-green'} font-bold`}>
                {privateLog ? 'Privado' : 'Público'}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <Button onClick={() => window.open(fileUrl)} className="mr-2 bg-primary text-white">
                <IoEye size="20px" />
              </Button>
              <Button onClick={handleDownloadAttachment} className="bg-primary text-white mr-2">
                <HiOutlineDownload size="20px" />
              </Button>
              <LawyerAccess>
                <Button onClick={handleDeleteAttachment} className="bg-red bg-opacity-20 text-red">
                  <IoTrashBinOutline size="20px" />
                </Button>
              </LawyerAccess>
            </div>
          </div>
        </div>
      </Card>)}
    </>
  )
}