import React from 'react';
import { useDispatch } from 'react-redux';
import { IoCheckmarkOutline, IoCloseCircleSharp, IoEllipsisVertical } from 'react-icons/io5';

import Card from '../ui/Card';
import Dropdown from '../ui/Dropdown';
import Button from '../ui/Button';
import ToggleSwitch from '../ui/ToggleSwitch';
import avatar from '../../assets/images/user.png';
import Temporal from '../ui/Temporal';
import Message from '../ui/Message';
import { DropdownItem } from '../ui/Dropdown/Item';

import { ListCardProps } from './types';
import { useUploadUrl, usePortal } from '../../hooks';

import * as userActions from '../../store/user/actions';

import { ReactComponent as BlueBrandIcon } from '../../assets/images/illustration-lawyers.svg';

export default function LawyerCard({ bgColor = 'bg-skyblue', onClick = () => { }, onDisabledChange = () => { }, ...entity }: ListCardProps): JSX.Element {
  const dispatch = useDispatch();
  // const role = useUserRole();
  // const Portal = usePortal(<></>, 'delete_entity');
  const Portal = usePortal(<></>, 'create_lawyer');

  const image = useUploadUrl(entity?.image);
  function handleEdit() {
    dispatch(userActions.setLawyerModal({ active: true, edition: true, form: { ...entity } }));
  }

  const portalMessage = ({ success, message, disabled }: { success: boolean; message: string; disabled: boolean; }) =>
    Portal.add(<Temporal duration={6000}>
      <Message
        icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
        container={{
          className: "fixed bottom-10  z-20 w-2/3  z-50",
          style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
        }}
        className="text-lg font-medium border-2 border border-gray-200 shadow-xl rounded-lg text-center md:text-left"
        color="bg-white"

      >
        {success ? `Se ha ${disabled ? 'deshabilitado' : 'habilitado'} el abogado correctamente` : message}
      </Message>
    </Temporal>)
  function handleDisable(disabled: boolean) {
    dispatch(userActions.updateLawyer({ id: entity.id as number, fields: { disabled } }, (res): void => {
      portalMessage({ ...res, disabled });
    }));
  }

  return (
    <Card className={`transform relative duration-300 cursor-pointer hover:scale-95 overflow-hidden	p-0 border-none relative ${bgColor}`}>
      <div className="w-full">
        {
          <div className="absolute right-2 z-20 top-2">
            <ToggleSwitch defaultValue={!entity?.disabled} onChange={(val) => handleDisable(!val)} />
          </div>
        }
        <div onClick={onClick} className="break-words flex top-0 left-0 relative flex-col h-full text-gray-800 p-5" style={{ minHeight: 200 }}>
          <div className="flex flex-col">
            <div>
              <img src={entity?.image ? image : avatar} className="bg-cover w-20 h-20 rounded-full" alt="Abogado - foto perfil" />
            </div>
            <div className="flex flex-col items-start " style={{ width: 'calc(100% - 35px)' }} >
              <p className="text-2xl truncate font-bold overflow-ellipsis w-full">{entity?.name || 'Sin información'}</p>
              <p className="overflow-ellipsis"><strong>User: </strong>{entity?.email || 'Sin información'}</p>
            </div>

          </div>


          <BlueBrandIcon className="absolute right-0" style={{ zIndex: -1 }} />
        </div>
        <div className="flex justify-end items-end absolute right-2 bottom-4">
          <Dropdown trigger={(
            <Button className="bg-white text-primary rounded-full border-none" padding="px-1 py-3">
              <IoEllipsisVertical />
            </Button>
          )} dropdownList={{ style: { width: 100, bottom: '100%' } }}>
            <DropdownItem onClick={handleEdit}>Editar</DropdownItem>
          </Dropdown>
        </div>
      </div>
    </Card >
  )


}