import { CreateDefendantResponse, DeleteDefendantResponse, UpdateDefendantResponse } from "../../api/defendant/types";
import { Defendant } from "../../interfaces/entities";

// TYPES
const types: Record<string, string> = {
  FETCH_DEFENDANTS_REQUEST: 'FETCH_DEFENDANTS_REQUEST',
  FETCH_DEFENDANTS_SUCCESS: 'FETCH_DEFENDANTS_SUCCESS',

  SET_DEFENDANT_MODAL: 'SET_DEFENDANT_MODAL',

  CREATE_DEFENDANT_REQUEST: 'CREATE_DEFENDANT_REQUEST',

  UPDATE_DEFENDANT_REQUEST: 'UPDATE_DEFENDANT_REQUEST',
  UPDATE_DEFENDANT_SUCCESS: 'UPDATE_DEFENDANT_SUCCESS',

  DELETE_DEFENDANT_REQUEST: 'DELETE_DEFENDANT_REQUEST'
}



export interface DefendantModal {
  active: boolean;
  edition: boolean;
  form: Partial<Defendant>
}
export interface DefendantFeedback {
  active: boolean;
  success: boolean;
  message: string;
}

// Defendant State Type
export interface DefendantType {
  defendants: Defendant[] | null;
  pagesCount: number;
  defendantsCount: number;

  defendantModal: DefendantModal;
  defendantFeedback: DefendantFeedback;
}



// payloads
export type FetchDefendantsPayload = { page: number; search: string; };
export type FetchDefendantsSuccessPayload = { defendants: Defendant[]; pagesCount: number; defendantsCount: number; }

// actions
export interface FetchDefendantsAction {
  type: typeof types.FETCH_DEFENDANTS_REQUEST,
  payload: FetchDefendantsPayload,
  cb: () => void
}

export interface FetchDefendantsSuccessAction {
  type: typeof types.FETCH_DEFENDANTS_SUCCESS,
  payload: FetchDefendantsSuccessPayload
}

export interface DefendantModalAction {
  type: typeof types.SET_CLIENT_MODAL;
  payload: Partial<DefendantModal>;
}

export interface CreateDefendantAction {
  type: typeof types.CREATE_DEFENDANT_REQUEST,
  cb: (res: CreateDefendantResponse) => void
  payload: null;
}


export interface UpdateDefendantAction {
  type: typeof types.UPDATE_DEFENDANT_REQUEST,
  payload: {
    id: Defendant['id'];
    fields: Partial<Defendant>
  };
  cb: (res: UpdateDefendantResponse) => void;
}

export interface UpdateDefendantSuccessAction {
  type: typeof types.UPDATE_DEFENDANT_SUCCESS;
  payload: UpdateDefendantAction['payload'];
}

export interface DeleteDefendantAction {
  type: typeof types.DELETE_DEFENDANT_REQUEST,
  payload: { id: Defendant['id'] };
  cb: (res: DeleteDefendantResponse) => void

}

export type Action =
  | FetchDefendantsAction
  | FetchDefendantsSuccessAction
  | DefendantModalAction
  | CreateDefendantAction
  | UpdateDefendantAction
  | UpdateDefendantSuccessAction
  | DeleteDefendantAction;

export default types;
