import React, { ChangeEvent, useEffect, useState } from 'react';

import { IoCheckmarkOutline, IoChevronDown, IoCloseCircleSharp, IoInformationCircleSharp, IoRefresh } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEntityFormattedName, usePortal } from '../../hooks';


import { Client } from '../../interfaces/entities';
import { fetchClients, setClientModal, updateClient } from '../../store/client/actions';
import { ClientType } from '../../store/client/types';
import { setProcessModal } from '../../store/process/actions';
import { State } from '../../store/reducers';

import ListCard from '../ListCard';
import Button from '../ui/Button';
import Dropdown from '../ui/Dropdown';
import { Input, InputGroup, Label } from '../ui/Input';
import Message from '../ui/Message';
import Pagination from '../ui/Pagination';
import { ReactComponent as AddClientIllustration } from '../../assets/images/add-client-illustration.svg';


import { ClientsListProps } from './types';
import { setDefendantModal } from '../../store/defendant/actions';
import Temporal from '../ui/Temporal';
import { setLogModal } from '../../store/log/actions';
import { setPaymentModal } from '../../store/payment/actions';


export default function ClientsList({ selectList }: ClientsListProps) {
  const [search, setSearch] = useState<string>('');
  const paginationState = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>();
  const router = useHistory();
  const { clients, pagesCount, clientsCount } = useSelector((state: State): ClientType => state.client);
  const dispatch = useDispatch();
  const Portal = usePortal(<></>, 'disable_client');

  const { prefix, formattedName } = useEntityFormattedName();
  function handleChangePage(p: number) {
    setLoading(true);

    dispatch(fetchClients({ search, page: p }, () => {
      setLoading(false);
    }))
  }

  function handleOpenCreateClientModal() {
    dispatch(setClientModal({
      active: true
    }));
  }
  function handleReload() {
    setLoading(true);
    paginationState[1](0);
    dispatch(fetchClients({ search, page: 0 }, () => {
      setLoading(false);
    }))
  }
  function handleSearchChange(e: ChangeEvent<{ value: string; name: string | undefined }>) {
    e.persist();
    e.preventDefault();
    setLoading(true);
    clearTimeout(timer);

    const tm = setTimeout(() => {
      setSearch(e.target.value);
      dispatch(fetchClients({ search: e.target.value, page: paginationState[0] }, () => {
        setLoading(false);
      }))
    }, 1500);

    setTimer(tm);
  }
  function handleDisableClient(id: number, disabled: boolean) {
    dispatch(updateClient({
      id,
      fields: {
        disabled
      }
    }, ({ success, message }) => {
      Portal.add(<Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-5/6  z-50",
            style: { marginLeft: 'calc(calc(100% / 6) / 2)' }
          }}
          className="text-lg font-medium shadow-xl rounded-lg text-center md:text-left"
          color="bg-white"
        >
          {!success ? message : `Se ha ${disabled ? 'deshabilitado' : 'habilitado'} el cliente correctamente`}
        </Message>
      </Temporal>)
    }))
  }

  // open modals


  function handleOpenModal(action: (payload: any) => void, payload: any) {
    dispatch(action(payload));
  }



  useEffect(() => {
    dispatch(fetchClients({ search, page: 0 }, () => {
      setLoading(false);
    }));
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    paginationState[1](0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsCount]);

  return (
    <section className="flex items-center w-full flex-col bg-gray-100 pb-10" style={{ minHeight: 'calc(100vh - 80px)' }}>
      {
        Boolean(clients) && Boolean(clientsCount) && (
          <>
            <h1 className="text-4xl	text-primary font-bold mt-10 text-center">{prefix ? prefix + '.' : ''} {formattedName}</h1>
            <h1 className="text-3xl	text-gray-500 font-medium mt-5">Clientes</h1>
            <div className="flex flex-col sm:items-center sm:flex-row sm:justify-between w-full pr-2 pl-2 md:pr-20 md:pl-20 mb-5 mt-5 items-center">
              <InputGroup className="sm:w-3/12">
                <Label>Buscar cliente</Label>
                <Input type="text" onChange={handleSearchChange} loading={loading} placeholder="Escriba el nombre de un cliente"></Input>
              </InputGroup>
              <div className="w-full mt-5 sm:mt-0 md:w-1/3 flex items-end">
                <div>
                  {selectList}
                </div>
                <InputGroup tabIndex={-1} className="ml-2" id="inputGroup">
                  <div className="w-full text-right pb-2">
                    <Label>Agregar</Label>
                  </div>
                  <Dropdown trigger={<Button className="w-full bg-white flex justify-between items-center"><>Seleccione <IoChevronDown /></></Button>}>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setClientModal, { active: true })}>Cliente</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setDefendantModal, { active: true })}>Demandado</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setProcessModal, { active: true, edition: false })}> Proceso</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setLogModal, { active: true })}>Actividad</div>
                    <div className="hover:bg-gray-200 duration-200 text-right p-2 cursor-pointer" onClick={() => handleOpenModal(setPaymentModal, { active: true })}>Pago</div>
                  </Dropdown>
                </InputGroup>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full pr-2 pl-2 md:pr-20 md:pl-20 pb-10">
              {clients?.map((client: Client) => (
                <ListCard onDisabledChange={() => handleDisableClient(client?.id, !client.disabled)} onDelete={handleReload} onClick={() => router.push(`/member/client/${client.id}`)} key={client.id} {...client} />
              ))}
            </div>
            <div className="w-full pr-2 pl-2 md:pr-20 md:pl-20">
              {!clients?.length && clientsCount && (<Message
                icon={<IoInformationCircleSharp size="30px" color="skyblue" />}
                container={{ className: "w-full z-50" }}
                className="text-lg font-medium shadow-lg rounded-lg"
                color="bg-white"
              >
                No se han encontrado clientes con el filtro actual
              </Message>)}
            </div>

            {pagesCount > 0 && (
              <>
                <section>
                  <Pagination state={paginationState} onChange={handleChangePage} pagesCount={pagesCount} />
                </section>
                <div className="w-full relative mt-10 md:mt-0">
                  <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15 absolute md:bottom-0 right-1 md:right-20" onClick={handleReload}><IoRefresh /></Button>
                </div>
              </>
            )}
          </>
        )}
      {!clientsCount && (<section className="flex overflow-auto flex-col justify-center items-center" style={{ minHeight: 'calc(100vh - 80px)' }}>
        <>
          <h1 className="text-4xl	text-primary font-bold mt-2 text-center">{prefix ? prefix + '.' : ''} {formattedName}</h1>

          <p className="font-black text-xl sm:text-2xl mt-5 text-gray-600 mb-10 sm:mb-5 text-center">Agregue a su primer cliente</p>
          <AddClientIllustration style={{ minWidth: '300px', width: '30%' }} />
          <Button onClick={handleOpenCreateClientModal} className="mt-10 bg-primary text-white pr-10 pl-10" >Crear cliente</Button>
        </>
      </section>)}
    </section>
  )
}