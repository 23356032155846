import React, { FocusEvent, useState } from 'react';
import { Option, SelectProps } from './types';
import _ from 'lodash';


export default function Select<OptionType>({ options, defaultValue = options[0], className = '', onChange, label = null, customTrigger = null, btnTriggerClassName = '', position = 'bottom' }: SelectProps<OptionType>): JSX.Element {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Option<OptionType>>(defaultValue);
  const [id] = useState<string>(_.uniqueId('select_'));

  function handleSelect(op: Option<OptionType>) {
    setSelected(op);
    setIsOpen(false);
    onChange(op.value);
  }

  function handleBlur(e: FocusEvent<HTMLElement>) {
    e.preventDefault();
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (!relatedTarget?.classList?.contains(id)) {
      setIsOpen(false);
    }
  }

  function handleToggleSelect() {
    if (isOpen) setIsOpen(false);
    else setIsOpen(true);
  }

  return (
    <div onBlur={handleBlur} tabIndex={1} className={`w-full cursor-pointer outline-none ${className}`}>
      <div className="w-full outline-none">
        {label}
        <div className="mt-1 relative  outline-none">
          <button type="button"
            onClick={handleToggleSelect}
            className={`relative w-full bg-white border border-gray-200 rounded-md shadow-sm hover:shadow-md duration-200  pl-3 pr-10 py-2 text-left cursor-default outline-none focus:outline-none  cursor-pointer ${btnTriggerClassName}`}>
            {customTrigger ? customTrigger : (<>
              <div className="flex w-full items-center">
                {selected?.image && <img src={selected?.image} alt="option" className="flex-shrink-0 h-6 w-6 rounded-full" />}
                {typeof selected.text === typeof '' ? (
                  <span className="ml-3 block truncate">
                    {selected.text}
                  </span>
                ) : selected.text}
              </div>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </span>
            </>)}
          </button>
          {
            isOpen && (
              <div className="absolute mt-1 w-full z-10 rounded-md bg-white shadow-lg transition ease-in-out duration-200" style={position === 'top' ? { bottom: 'calc(100% + 5px)' } : {}}>
                <ul tabIndex={-1} role="listbox" aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-item-3"
                  className={`max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${id}`}>
                  {
                    options.map((op: Option<OptionType>, index: number) => {
                      return (
                        <li id="listbox-item-0" role="option" onClick={() => handleSelect(op)} aria-selected={false} key={index} className={`text-gray-900 cursor-pointer hover:bg-gray-100 select-none relative py-2 pl-3 ${typeof op.text === typeof '' ? 'pr-9' : 'pr-3'}`}>
                          <div className="flex items-center">
                            {op?.image && <img src={op?.image} alt="option" className="flex-shrink-0 h-6 w-6 rounded-full" />}
                            {typeof op.text === typeof '' ? (
                              <span className="ml-3 block font-normal truncate">
                                {op.text}
                              </span>
                            ) : op.text}
                          </div>
                        </li>
                      )
                    })
                  }

                </ul>
              </div>
            )
          }
        </div>
      </div>
    </div>)
}