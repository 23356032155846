import { CreateClientResponse, DeleteClientResponse, UpdateClientResponse } from "../../api/client/types";
import { Client } from "../../interfaces/entities";

const types: Record<string, string> = {
  FETCH_CLIENTS_REQUEST: 'FETCH_CLIENTS_REQUEST',
  FETCH_CLIENTS_SUCCESS: 'FETCH_CLIENTS_SUCCESS',

  // create
  CREATE_CLIENT_REQUEST: 'CREATE_CLIENT_REQUEST',


  // client modal
  SET_CLIENT_MODAL: 'SET_CLIENT_MODAL',

  SET_CREATE_OR_EDIT_CLIENT_FEEDBACK: 'SET_CREATE_OR_EDIT_CLIENT_FEEDBACK',

  // get client
  GET_CLIENT_REQUEST: 'GET_CLIENT_REQUEST',
  GET_CLIENT_RESULT: 'GET_CLIENT_RESULT',

  SET_CLIENT_STATE: 'SET_CLIENT_STATE',

  // update client
  UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',

  // delete client
  DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST'

}

export interface ClientModal {
  active: boolean;
  edition: boolean;
  form: Partial<Client>
}
export type Feedback = {
  success: boolean;
  active: boolean;
  message: string;
};

export interface ClientType {
  clients: Client[] | null;
  pagesCount: number;
  clientsCount: number;

  gettedClient: Client | null;
  // create or edit client modal
  clientModal: ClientModal;
  createOrEditClientFeedback: Feedback
}


//Payload
export type FetchClientsPayload = { page: number; search: string; };
export interface FetchClientsSuccessPayload {
  clients: Client[],
  pagesCount: number;
  clientsCount: number;
}


export type CreateClientPayload = Partial<Client>;

// Actions
export interface FetchClientsAction {
  type: typeof types.FETCH_CLIENTS_REQUEST,
  payload: FetchClientsPayload,
  cb: () => void
}

export interface FetchClientsSuccessAction {
  type: typeof types.FETCH_CLIENTS_SUCCESS;
  payload: FetchClientsSuccessPayload;
}


// create client

export interface CreateClientAction {
  type: typeof types.CREATE_CLIENT_REQUEST;
  payload: null;
  cb: (response: CreateClientResponse) => void
}


export interface ClientModalAction {
  type: typeof types.SET_CLIENT_MODAL;
  payload: Partial<ClientModal>;
}


export interface GetClientAction {
  type: typeof types.GET_CLIENT_REQUEST;

  cb: (response: { success: boolean; message?: string; }) => void;

  payload: {
    id: number;
  }
}

export interface GetClientResultAction {
  type: typeof types.GET_CLIENT_RESULT;
  payload: Partial<Client>;
}

export interface SetClientStateAction {
  type: typeof types.SET_CLIENT_STATE;
  payload: Partial<ClientType>;
}


export interface UpdateClientAction {
  type: typeof types.UPDATE_CLIENT_REQUEST;
  payload: { fields: Partial<Client>, id: number };
  cb: (res: UpdateClientResponse) => void;
}

export interface UpdateClientSuccessAction {
  type: typeof types.UPDATE_CLIENT_SUCCESS;
  payload: UpdateClientAction['payload'];
}

export interface DeleteClientAction {
  type: typeof types.DELETE_CLIENT_REQUEST,
  payload: { id: Client['id'] },
  cb: (res: DeleteClientResponse) => void;
}

export type Action =
  | FetchClientsAction
  | FetchClientsSuccessAction
  | CreateClientAction
  | ClientModalAction
  | GetClientAction
  | GetClientResultAction
  | SetClientStateAction
  | UpdateClientAction
  | UpdateClientSuccessAction
  | DeleteClientAction;

export default types;
