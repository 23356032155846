import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import clientSaga from './client/saga';
import defendantSaga from './defendant/saga';
import processSaga from './process/saga';
import logSaga from './log/saga';
import userSaga from './user/saga';
import paymentSaga from './payment/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    clientSaga(),
    processSaga(),
    defendantSaga(),
    logSaga(),
    userSaga(),
    paymentSaga()
  ]);
}