import { SagaIterator } from 'redux-saga';
import { call, select, takeLatest } from 'redux-saga/effects';

import { Payment } from '../../api/payment';
import { State } from '../reducers';
import types, { CreatePaymentAction, DeletePaymentAction, UpdatePaymentAction } from './types';


function* createPayment({ cb, payload }: CreatePaymentAction): SagaIterator {
  try {
    const { gettedProcess } = yield select((state: State): State['process'] => state.process);

    const form: State['payment']['paymentModal']['form'] = yield select((state: State): State['payment']['paymentModal']['form'] => state.payment.paymentModal.form);
    const formData = new FormData();
    formData.append('date', form?.date || new Date().toISOString());
    formData.append('status', String(gettedProcess?.status || 0));

    if (form?.content) formData.append('content', form?.content);

    if (form?.internalId) formData.append('internalId', String(form?.internalId));

    if (form?.processId) formData.append('processId', String(form?.processId));

    if (form?.amount) formData.append('amount', String(form?.amount));

    if (payload?.attachment) formData.append('attachment', payload?.attachment);

    const res = yield call(Payment.create, formData);

    yield call(cb, res);

  }
  catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });

  }
}

function* updatePayment({ cb, payload }: UpdatePaymentAction): SagaIterator {
  try {
    const { id, fields: { user, ...fields } } = payload;
    const { attachment, ...flds } = fields;
    const formData = new FormData();

    formData.append('id', String(id));
    formData.append('fields', JSON.stringify(flds));
    if (attachment) formData.append('attachment', attachment);
    const res = yield call(Payment.update, formData);

    yield call(cb, res);



  }
  catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });

  }
}
function* deletePayment({ cb, payload }: DeletePaymentAction): SagaIterator {
  try {
    const res = yield call(Payment.delete, payload);
    yield call(cb, res);
  }
  catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });

  }
}

export default function* paymentSaga(): SagaIterator {
  yield takeLatest(types.CREATE_PAYMENT_REQUEST, createPayment);
  yield takeLatest(types.UPDATE_PAYMENT_REQUEST, updatePayment);
  yield takeLatest(types.DELETE_PAYMENT_REQUEST, deletePayment);
}