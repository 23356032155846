import types, { PaymentType, Action, SetPaymentModalAction } from './types';


const initialState: PaymentType = {
  paymentModal: {
    active: false,
    edition: false,
    form: {}
  },
}
export default function paymentReducer(state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PAYMENT_MODAL: {
      const value = payload as SetPaymentModalAction['payload'];
      return {
        ...state,
        paymentModal: {
          active: value?.active ?? state.paymentModal.active,
          edition: value?.edition ?? state.paymentModal.edition,
          form: {
            ...state.paymentModal.form, ...(value?.form || {}),
          }
        }
      }
    }

    default:
      return state;
  }

}