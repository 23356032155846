import React, { Dispatch, useEffect, useState } from 'react';

import MemberNavbar from '../../components/MemberNavbar';

import CreateClient from '../../components/CreateClient';

import { IoPeople } from 'react-icons/io5';
import ClientsList from '../../components/ClientsList';
import CreateProcess from '../../components/CreateProcess';
import Select from '../../components/ui/Select';
import DefendantList from '../../components/DefendantsList';
import CreateDefendant from '../../components/CreateDefendant';
import { roles } from '../../helpers/constants';
import { useUserRole } from '../../hooks/useUserRole';
import { useHistory } from 'react-router-dom';
import { State } from '../../store/reducers';
import { AuthType } from '../../store/auth/types';
import { useSelector } from 'react-redux';
import LawyerAccess from '../../components/LawyerAccess';
import CreateLog from '../../components/CreateLog';
import CreatePayment from '../../components/CreatePayment';

function SelectList({ setList, list }: { setList: Dispatch<string>, list: string }) {
  const options = [{
    text: 'Clientes',
    value: 'clients'
  }, {
    text: 'Demandados',
    value: 'defendants'
  }]
  function handleListChange(val: string) {
    setList(val);
  }
  return <Select<string> btnTriggerClassName="bg-primary text-white" customTrigger={(
    <div className="p-1 flex  outline-none items-center justify-center">
      <IoPeople size="20px" style={{ marginRight: 10 }} />

      {options.find((opt) => opt.value === list)?.text || ''}
      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </span>
    </div>
  )} onChange={handleListChange} options={options} />
}


export default function MemberHome(): JSX.Element {
  const [list, setList] = useState<string>('clients');
  const history = useHistory();
  const role = useUserRole();
  const { entity } = useSelector((state: State): AuthType => state.auth);

  useEffect(() => {
    if (entity != null) {
      if (role === roles.CLIENT) history.push(`/member/client/${entity.id}`);
    }
  }, [entity, history, role])
  return (
    <LawyerAccess>
      <div>
        <MemberNavbar />
        <CreateClient />
        <CreateProcess />
        <CreateDefendant />
        <CreateLog />
        <CreatePayment />
        {list === 'clients' && (<ClientsList selectList={<SelectList list={list} setList={setList} />} />)}
        {list === 'defendants' && (<DefendantList selectList={<SelectList list={list} setList={setList} />} />)}

      </div >
    </LawyerAccess>

  )
} 