import { useEffect, useState } from 'react';
import { TemporalProps } from './types';


export default function Temporal({ duration, children }: TemporalProps): JSX.Element {
  const [active, setActive] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setActive(false), duration);
    return () => clearTimeout(timeout);
  }, [duration]);

  return <>
    {active && children}
  </>;
}