import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IoCheckmarkOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { usePortal } from '../../hooks';
import { State } from '../../store/reducers';
import Button from '../ui/Button';
import { Input, InputGroup, Label } from '../ui/Input';
import Message from '../ui/Message';
import Modal from '../ui/Modal';
import Temporal from '../ui/Temporal';
import CreatePaymentModalWrapper from './styles';
import { PaymentType } from '../../store/payment/types';
import { createPayment, setPaymentModal, updatePayment } from '../../store/payment/actions';
import { BsFileEarmarkArrowUp } from 'react-icons/bs';
import { CreatePaymentProps } from './types';



export default function CreatePayment({ onCreate = () => { } }: CreatePaymentProps): JSX.Element {
  const { paymentModal: { active, form, edition } } = useSelector((state: State): PaymentType => state.payment);
  const [attachment, setAttachment] = useState<File | undefined>();
  const [attachmentError, setAttachmentError] = useState<string | undefined>();
  const dispatch = useDispatch();
  const inputAttachment = useRef<any>();
  const Portal = usePortal();

  function handleClose(): void {
    const resettedForm = _.mapValues(form, () => undefined);
    setAttachmentError(undefined);

    dispatch(setPaymentModal({ active: false, edition: false, form: resettedForm }));
  }

  function handleSubmit(e: FormEvent) {
    e.persist();
    e.preventDefault();
    const portalMessage = ({ success, message }: { success: boolean, message: string | null }) => Portal.add(
      <Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium shadow-xl border-2 border border-gray-200 rounded-lg  text-center md:text-left"
          color="bg-white"
        >
          {!success ? `${message}` : `Se ha ${edition ? 'modificado' : 'agregado'} el pago correctamente`}
        </Message>
      </Temporal>
    );
    if (!edition) {
      dispatch(createPayment({ attachment: attachment ?? undefined }, (res) => {
        if (res?.success) {
          onCreate();
          portalMessage(res);
          handleClose();
        }
      }))
    } else {
      const { id, ...frm } = form;
      const fields = {
        ...frm,
        attachment: attachment ?? undefined
      }
      dispatch(updatePayment({ id: id as number, fields }, (res) => {
        onCreate();
        portalMessage(res);
        handleClose();
      }))
    }
  }
  function handleInputChange(e: ChangeEvent<{ value: string; name: string | undefined }>) {
    e.persist();
    dispatch(setPaymentModal({ form: { [e.target.name as string]: e.target.value } }))

  }
  function handleSetAttachment(e: ChangeEvent<{ files: FileList | null }>) {
    e.persist();
    const avExtensions: string[] = ['pdf', 'jpg', 'png', 'svg', 'jpeg'];
    const attachment: File | undefined = e?.target?.files ? e?.target?.files[0] : undefined;

    if (attachment) {
      const extensions = attachment?.name?.split('.');
      const ext = extensions[extensions.length - 1];
      if (avExtensions.includes(ext)) {
        setAttachment(attachment);
        setAttachmentError(undefined);
      } else
        setAttachmentError(`Las extensiones permitidas son las siguientes: ${avExtensions.join(', ')}`)
    }

  }
  useEffect(() => {
    return () => {
      setAttachmentError(undefined);
    }
  }, [active])
  return (
    <CreatePaymentModalWrapper>
      <Modal isActive={active} onClose={handleClose}>
        <form onSubmit={handleSubmit} className="flex justify-start flex-col">
          <div className="w-full flex justify-center sm:justify-between mb-5 flex-col sm:flex-row font-bold items-center">
            <p className="text-2xl">{edition ? 'Modificar Pago' : 'Agregar pago'}</p>
            <div>
              <Input autoFocus defaultValue={moment.utc(form?.date || new Date()).format('YYYY-MM-DD')} type="date" name="date" onChange={handleInputChange}></Input>
            </div>
          </div>
          {
            !Boolean(form?.processId) && (
              <InputGroup className="mb-4">
                <Label color="text-gray-600" className="text-base">Número de proceso:</Label>
                <Input onChange={handleInputChange} name="internalId" required type="number" placeholder="Ej : 901283012" />
              </InputGroup>
            )
          }
          <InputGroup className="mb-4">
            <Label color="text-gray-600" className="text-base">Contenido:</Label>
            <Input multiline onChange={handleInputChange} defaultValue={form?.content || ''} name="content" required type="text" placeholder="Ej: Aclaraciones del pago" />
          </InputGroup>
          <InputGroup className="mb-4">
            <Label color="text-gray-600" className="text-base">Monto:</Label>
            <Input onChange={handleInputChange} defaultValue={form?.amount || ''} name="amount" required type="number" placeholder="Ej: 1000" />
          </InputGroup>

          {
            attachmentError && (
              <Message rounded className="mt-5 mb-5" closable={false} icon={<BiInfoCircle size="20px" className="tetx-blue" />}>
                {attachmentError}
              </Message>
            )
          }
          <div onClick={() => inputAttachment.current.click()} className="cursor-pointer flex justify-center text-gray-700 items-center h-28 w-full border-2 border-dashed border-gray-100 rounded-xl">

            {attachment ? (
              <span className="bg-green bg-opacity-30 px-12 rounded-xl py-3">
                {attachment?.name}
              </span>
            ) : <BsFileEarmarkArrowUp size="23px" />}
            <input ref={inputAttachment} type="file" style={{ display: 'none' }} onChange={handleSetAttachment} />
          </div>
          <Button className="bg-primary text-white w-full mt-5">
            {edition ? 'Guardar cambios' : 'Agregar pago'}
          </Button>
        </form>
      </Modal>
    </CreatePaymentModalWrapper>
  )
}