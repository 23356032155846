import React from 'react';
import { ProgressProps } from './types';



export default function Progress({ value, color = '#142432' }: ProgressProps): JSX.Element {
  return (
    <div className="w-full bg-trasparent rounded-3xl" style={{
      border: `1px solid ${color}`,
      height: 16
    }}>
      <div className="h-full rounded-3xl m-0 p-0" style={{
        border: `0px solid ${color}`,
        width: `${value}%`,
        backgroundColor: color,

      }}></div>
    </div>
  )
}