import styled from 'styled-components';


const CreateUserModalWrapper = styled.div`
  .modal-body{
    width: 50vw;
    @media screen and (min-width: 769px) and (max-width: 1200px){
      width: 70vw;
    }
    @media screen and (max-width: 768px){
      width: 100vw !important;
    }
  }
`

export default CreateUserModalWrapper; 