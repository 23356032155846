import types, { Action, GetProcessSuccessAction, ProcessModal, ProcessType, SetProcessStateAction } from "./types";

const initialState: ProcessType = {
  pageSize: 1,
  processes: null,
  gettedProcess: null,
  processModal: {
    active: false,
    edition: false,
    form: {}
  }
}

export default function processReducer(state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PROCESS_MODAL: {
      const value = payload as Partial<ProcessModal>;
      return {
        ...state, processModal: {
          active: value?.active ?? state.processModal.active,
          edition: value?.edition ?? state.processModal.edition,
          form: { ...state.processModal.form, ...(value?.form || {}) }
        }
      }
    }
    case types.CREATE_PROCESS_SUCCESS: {
      return {
        ...state,
        processModal: {
          active: false,
          edition: false,
          form: {}
        }
      }
    }
    case types.CREATE_PROCESS_FAILED: {
      return {
        ...state,
        processModal: {
          active: false,
          edition: false,
          form: {}
        }
      }
    }
    case types.GET_PROCESS_SUCCESS: {
      const gettedProcess = payload as GetProcessSuccessAction['payload'];
      return {
        ...state,
        gettedProcess
      }
    }
    case types.SET_PROCESS_STATE: {
      const newState = payload as SetProcessStateAction['payload'];
      return { ...state, ...newState }
    }
    default:
      return state;
  }
}