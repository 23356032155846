import { useEffect, useState } from "react";

export const processStatus: Record<number, string> = {
  // 0%
  0: 'Elaboración',
  20: 'Calificación',
  40: 'Citación',
  60: 'Audiencia',
  80: 'Sentencia',
  100: 'Completado'
}

export const processStatusColors: Record<number, string> = {
  0: 'rgb(156, 163, 175)',
  20: 'rgb(59, 130, 246)',
  40: '#000000',
  60: 'rgb(217, 119, 6)',
  80: 'rgb(252, 211, 77)',
  100: 'rgb(16, 185, 129)'
}


export function useProcessStatus(value: number) {
  const [statusData, setStatusData] = useState<{ text: string, color: string }>({
    text: 'No hemos podido hallar el estado del Proceso',
    color: 'transparent'
  })

  useEffect(() => {
    if (value in processStatus) {
      setStatusData({ text: processStatus[value], color: processStatusColors[value] });
    }
  }, [value]);
  return statusData;
}
