import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Defendant } from '../../api/';
import { State } from '../reducers';

import { fetchDefendantsSuccess, updateDefendantSuccess, fetchDefendants } from './actions';
import types, { CreateDefendantAction, DefendantType, DeleteDefendantAction, FetchDefendantsAction, UpdateDefendantAction } from './types';


function* fetchDefendantsSaga({ payload, cb }: FetchDefendantsAction): SagaIterator {
  try {
    const { search, page } = payload;
    const { defendants, success, pagesCount, defendantsCount } = yield call(Defendant.fetchAll, {
      search, page, pageSize: 6
    })
    if (success)
      yield put(fetchDefendantsSuccess({
        defendants,
        defendantsCount,
        pagesCount
      }));
  } catch (e) {
    console.log(e);
  }
  yield call(cb);
}

function* createDefendant({ cb }: CreateDefendantAction): SagaIterator {
  try {

    const { form } = yield select((state: State): DefendantType['defendantModal'] => state.defendant.defendantModal);

    const res = yield call(Defendant.create, form);
    if (res?.success)
      yield put(fetchDefendants({ search: '', page: 0 }, () => { }))

    yield call(cb, res);
  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado' });
  }
}

function* updateDefendant({ payload, cb }: UpdateDefendantAction): SagaIterator {
  try {
    const res = yield call(Defendant.update, payload);
    yield call(cb, res);
    if (res.success) {
      yield put(updateDefendantSuccess(payload))
      yield put(fetchDefendants({ search: '', page: 0 }, () => { }))

    }
  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado' });

  }
}

function* deleteDefendant({ payload, cb }: DeleteDefendantAction): SagaIterator {
  try {
    const res = yield call(Defendant.delete, payload);
    yield call(cb, res);

  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado' });

  }
}



export default function* defendantSaga(): SagaIterator {
  yield takeLatest(types.FETCH_DEFENDANTS_REQUEST, fetchDefendantsSaga);
  yield takeLatest(types.CREATE_DEFENDANT_REQUEST, createDefendant);
  yield takeLatest(types.UPDATE_DEFENDANT_REQUEST, updateDefendant);
  yield takeLatest(types.DELETE_DEFENDANT_REQUEST, deleteDefendant);
}