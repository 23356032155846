import React, { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Attachment } from '../../interfaces/entities';
import { downloadAttachmentsZip, setAttachmentsModal } from '../../store/log/actions';
import { LogType } from '../../store/log/types';
import { State } from '../../store/reducers';
import AttachmentCard from '../AttachmentCard';
import Button from '../ui/Button';
// import Button from '../ui/Button';
import Message from '../ui/Message';
import Modal from '../ui/Modal';
import ShowAttachmentsModalWrapper from './styles';
import { AttachmentsModalProps } from './types';



export default function AttachmentsModal({ client }: AttachmentsModalProps): JSX.Element {
  const { attachmentsModal: { active, private: privateLog, log } } = useSelector((state: State): LogType => state.log);
  const [attachments, setAttachments] = useState<Partial<Attachment>[]>([]);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setAttachmentsModal({ active: false, log: {}, private: false }));

  function handleDownloadAttachments() {
    dispatch(downloadAttachmentsZip({ id: log.id as number, private: privateLog }))
  }


  useEffect(() => {
    const attch = log?.attachments?.filter((attachment) => attachment?.private === privateLog) || [];
    setAttachments(attch);
  }, [log, privateLog]);

  return (
    <ShowAttachmentsModalWrapper>
      <Modal isActive={active} onClose={handleClose}>
        <>
          <h1 className="text-2xl">{client.officialId} - {client?.name}</h1>
          <br />
          <p className="text-lg">Historico de Documentos</p>
          <div className="h-96  w-full mt-3 overflow-y-auto px-2">
            {
              attachments.length ? attachments.map((attachment, index) =>
                <React.Fragment key={index}>
                  <AttachmentCard {...attachment} />
                </React.Fragment>
              ) : (
                  <Message rounded closable={false} icon={<BiInfoCircle size="20px" className="text-blue" />}>
                    No hemos encontrado adjuntos
                  </Message>
                )
            }
          </div>
          <div className="w-full">

            <Button onClick={handleDownloadAttachments} disabled={!attachments.length} className="disabled:opacity-50 bg-primary text-white w-full">
              Descargar Historico
            </Button>

          </div>
        </>
      </Modal>
    </ShowAttachmentsModalWrapper>
  );
}