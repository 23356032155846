import { Client } from "../../interfaces/entities";
import types, { ClientModal, Feedback, SetClientStateAction, GetClientResultAction, UpdateClientSuccessAction } from "./types";
import { Action, ClientType, FetchClientsSuccessPayload } from "./types";

const initialState: ClientType = {
  clients: null,
  pagesCount: 1,
  clientsCount: 0,

  gettedClient: null,

  // client modal
  clientModal: {
    active: false,
    edition: false,
    form: { legalPerson: true }
  },
  createOrEditClientFeedback: {
    success: false,
    active: false,
    message: ''
  }
}

export default function clientReducer(state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_CLIENTS_SUCCESS: {
      const { clients, pagesCount, clientsCount } = payload as FetchClientsSuccessPayload;
      return { ...state, clients, pagesCount, clientsCount };
    }
    case types.SET_CLIENT_MODAL: {
      const value = payload as Partial<ClientModal>;
      return {
        ...state,
        clientModal: {
          active: value?.active ?? state.clientModal.active,
          edition: value?.edition ?? state.clientModal.edition,
          form: {
            ...state.clientModal.form, ...(value?.form || {}),
          }
        }
      }
    }
    case types.SET_CREATE_OR_EDIT_CLIENT_FEEDBACK: {
      const value = payload as Partial<Feedback>;
      return {
        ...state,
        createOrEditClientFeedback: { ...state.createOrEditClientFeedback, ...value }
      }
    }
    case types.GET_CLIENT_RESULT: {
      const client = payload as GetClientResultAction['payload'];
      return { ...state, gettedClient: client };
    }
    case types.SET_CLIENT_STATE: {
      const p = payload as SetClientStateAction['payload'];
      return { ...state, ...p };
    }
    case types.UPDATE_CLIENT_SUCCESS: {
      const { id, fields } = payload as UpdateClientSuccessAction['payload'];
      let clients = [...state.clients || []];
      const clientIndex = clients.findIndex((cl: Client) => cl.id === id);
      if (clientIndex) {
        clients[clientIndex] = { ...clients[clientIndex], ...fields };
      }
      return {
        ...state, clients
      }
    }
    default:
      return state;
  }
}