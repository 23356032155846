import types, {
  CreateProcessAction, CreateProcessFailedAction, CreateProcessSuccessAction,
  FetchProcessesAction, FetchProcessesPayload,
  GetProcessAction, GetProcessSuccessAction,
  ProcessModal,
  SetProcessModalAction,
  UpdateProcessAction, UpdateProcessSuccessAction,
  SetProcessStateAction,
  SetToDoAction
} from "./types";

export const fetchProcesses = (payload: FetchProcessesPayload): FetchProcessesAction => ({
  type: types.FETCH_PROCESSES_REQUEST,
  payload
})
export const setProcessModal = (payload: Partial<ProcessModal>): SetProcessModalAction => ({
  type: types.SET_PROCESS_MODAL,
  payload
})

export const createProcess = (cb: CreateProcessAction['cb']): CreateProcessAction => ({
  type: types.CREATE_PROCESS_REQUEST,
  payload: null,
  cb
});

export const createProcessSuccess = (): CreateProcessSuccessAction => ({
  type: types.CREATE_PROCESS_SUCCESS,
  payload: null
})

export const createProcessFailed = (): CreateProcessFailedAction => ({
  type: types.CREATE_PROCESS_SUCCESS,
  payload: null
})

// update

export const updateProcess = (payload: UpdateProcessAction['payload'], cb: UpdateProcessAction['cb']): UpdateProcessAction => ({
  type: types.UPDATE_PROCESS_REQUEST,
  payload,
  cb
});

export const updateProcessSuccess = (payload: UpdateProcessAction['payload']): UpdateProcessSuccessAction => ({
  type: types.UPDATE_PROCESS_SUCCESS,
  payload
})

export const getProcess = (payload: GetProcessAction['payload'], cb: GetProcessAction['cb']): GetProcessAction => ({
  type: types.GET_PROCESS_REQUEST,
  payload,
  cb
});
export const getProcessSuccess = (payload: GetProcessSuccessAction['payload']): GetProcessSuccessAction => ({
  type: types.GET_PROCESS_SUCCESS,
  payload
});


export const setState = (payload: SetProcessStateAction['payload']): SetProcessStateAction => ({
  type: types.SET_PROCESS_STATE,
  payload
});



export const setToDO = (payload: SetToDoAction['payload'], cb: SetToDoAction['cb']): SetToDoAction => ({
  type: types.SET_TODO_REQUEST,
  payload,
  cb
});