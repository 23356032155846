import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HomePage, MemberHomePage, ClientPage, ProcessPage, LawyersPage } from './screens/';
import { verifySession as verifySessionAction } from './store/auth/actions';

import { PrivateRouteHOC } from './routing/PrivateRoute';



function App() {
  const dispatch = useDispatch();
  const history = useHistory();

  const verifySession = () =>
    dispatch(verifySessionAction());

  useEffect(() => {
    window.scrollTo(0, 0);

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    verifySession();

    return () => {
      unlisten();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Route path='/' component={HomePage} exact></Route>

      {/* Private routes */}

      <Route path='/member' component={PrivateRouteHOC(MemberHomePage)} exact></Route>
      <Route path='/member/client/:id' component={PrivateRouteHOC(ClientPage)} exact></Route>
      <Route path='/member/process/:id' component={PrivateRouteHOC(ProcessPage)} exact></Route>
      <Route path='/member/lawyers' component={PrivateRouteHOC(LawyersPage, ['1'])} exact></Route>
    </>
  );
}

export default App;
