import types, { CreateLogAction, DeleteAttachmentAction, DeleteLogAction, DownloadAttachmentsAction, SetAttachmentsModalAction, SetLogModalAction, UpdateLogAction } from './types';


export const createLog = (payload: CreateLogAction['payload'], cb: CreateLogAction['cb']): CreateLogAction => ({
  type: types.CREATE_LOG_REQUEST,
  payload,
  cb
});


export const setLogModal = (payload: SetLogModalAction['payload']): SetLogModalAction => ({
  type: types.SET_LOG_MODAL,
  payload
})

export const deleteLog = (payload: DeleteLogAction['payload'], cb: DeleteLogAction['cb']): DeleteLogAction => ({
  type: types.DELETE_LOG_REQUEST,
  payload,
  cb
})

export const updateLog = (payload: UpdateLogAction['payload'], cb: UpdateLogAction['cb']): UpdateLogAction => ({
  type: types.UPDATE_LOG_REQUEST,
  payload,
  cb
})

export const setAttachmentsModal = (payload: SetAttachmentsModalAction['payload']): SetAttachmentsModalAction => ({
  type: types.SET_ATTACHMENTS_MODAL,
  payload
})

export const deleteAttachment = (payload: DeleteAttachmentAction['payload'], cb: DeleteAttachmentAction['cb']): DeleteAttachmentAction => ({
  type: types.DELETE_ATTACHMENT_REQUEST,
  payload,
  cb
})

export const downloadAttachmentsZip = (payload: DownloadAttachmentsAction['payload']): DownloadAttachmentsAction => ({
  type: types.DOWNLOAD_ATTACHMENTS_REQUEST,
  payload
})