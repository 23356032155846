import React, { useState } from 'react';
import moment from 'moment';
import { IoCheckmarkOutline, IoCloseCircleSharp, IoEye } from 'react-icons/io5';

import { Payment } from '../../interfaces/entities';
import { useFormattedAmount, usePortal, useProcessStatus, useUploadUrl } from '../../hooks';

import Card from '../ui/Card';
import LawyerAccess from '../LawyerAccess';
import Button from '../ui/Button';
import Dropdown from '../ui/Dropdown';
import { DropdownItem } from '../ui/Dropdown/Item';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import Progress from '../ui/Progress';
import { useDispatch } from 'react-redux';
import { deletePayment, setPaymentModal } from '../../store/payment/actions';
import Confirm from '../ui/Confirm';
import Temporal from '../ui/Temporal';
import Message from '../ui/Message';

export default function PaymentCard({ payment, handleReload }: { payment: Partial<Payment>, handleReload: () => void }): JSX.Element {
  const { content, date, user, status, url } = payment;
  const [deletePaymentModal, setDeletePaymentModal] = useState<boolean>(false);
  const { color: statusColor } = useProcessStatus(Number(status));
  const amount = useFormattedAmount(payment?.amount || 0);
  const dispatch = useDispatch();
  const fileUrl = useUploadUrl(url || '');
  const userPhoto = useUploadUrl(user?.image);
  const Portal = usePortal();

  function handleUpdatePayment() {
    dispatch(setPaymentModal({ active: true, edition: true, form: payment }))
  }
  function handleDelete() {

    const portalMessage = ({ success, message }: { success: boolean, message: string | null }) => Portal.add(
      <Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium shadow-xl border-2 border border-gray-200 rounded-lg  text-center md:text-left"
          color="bg-white"
        >
          {!success ? `${message}` : `Se ha eliminado el pago correctamente`}
        </Message>
      </Temporal>
    );
    dispatch(deletePayment({ id: payment.id as number }, (res) => {
      portalMessage(res);
      setDeletePaymentModal(false);
      handleReload();
    }))

  }
  return (<>
    {deletePaymentModal && (<Confirm title={<p className="text-2xl">Estas seguro de eliminar este Pago?</p>} content={
      <p className="mt-2">Si eliminas este pago, no podrás recuperarlo en un futuro</p>
    } onAccept={handleDelete} onDeny={() => setDeletePaymentModal(false)} />)}
    <Card className="mt-2 border-2 border-primary" >
      <div className="w-full px-5 py-2 flex flex-wrap justify-between">
        <div className="flex items-center w-full md:w-auto flex-shrink md:pr-2 md:border-r-2 md:border-gray-200">
          <img style={{ width: 60, height: 60, backgroundSize: 'cover', borderRadius: '50%' }} alt="lawyer" src={userPhoto} />
          <div className="flex flex-col pl-5">
            <p className="font-bold text-lg w-full font-normal break-words flex-grow md:flex-none mr-2">
              {moment.utc(date || new Date()).format('DD/MM/YYYY')}
            </p>

            <Progress value={Number(status)} color={statusColor} />
          </div>
        </div>
        <div className="flex w-full md:w-auto flex-grow break-words items-center pl-5 py-2 md:py-0">
          <p className="text-base  w-full font-normal break-words flex-grow md:flex-none mr-2">
            {content}
          </p>
        </div>
        <div className="flex flex-wrap flex-row md:mt-0 w-full md:w-auto justify-end w-full xl:w-auto items-center">
          <div>
            <p className="text-xl font-medium">${amount}</p>
          </div>
          <div className="flex-auto ml-2 md:ml-5 flex items-end lg:items-end flex-col">
            <div className="flex sm:flex-col md:mt-5 mb-2 md:mb-0 items-end">
              <div>
                <LawyerAccess>
                  <Button onClick={() => window.open(fileUrl)} className={`mr-2 bg-primary text-white ${url === null ? 'bg-opacity-50' : ''}`} disabled={url === null}>
                    <IoEye size="20px" />
                  </Button>
                </LawyerAccess>
              </div>
              <div className="w-9 ml-5 md:ml-0 md:w-auto">
                <LawyerAccess>
                  <Dropdown dropdownList={{ width: 'w-auto', style: { marginTop: '-10px' } }} trigger={<Button border="border-none" padding="px-1 py-3" shadow="shadow-none" className="w-4 md:w-auto"><BiDotsHorizontalRounded size="30px" /></Button>}>
                    <DropdownItem textAlign="text-center" padding="px-5 py-2 bg-gray-100" onHoverClass="hover:bg-gray-200" onClick={handleUpdatePayment}>Editar</DropdownItem>
                    <DropdownItem textAlign="text-center" padding="px-5 py-2 bg-gray-100" onHoverClass="hover:bg-gray-200" onClick={() => setDeletePaymentModal(true)}>Eliminar</DropdownItem>
                  </Dropdown>
                </LawyerAccess>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </>)
}