
import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';
import types, { SignInAction, } from './types';
import { Auth, isAlreadyLogged, setAccessToken } from '../../api';

import { signInSuccess, setState } from './actions';

function* signIn({ payload, cb }: SignInAction) {
  try {
    const data = yield call(Auth.signIn, { ...payload, password: btoa(payload.password) });
    const { success, entity, token } = data;
    if (success) {
      yield call(setAccessToken, token);
      yield put(signInSuccess(entity));
    }
    yield call(cb, data);
  } catch (e) {
    console.log(e);
    yield call(cb, { success: false, message: 'Ha ocurrido un error inesperado' });
  }
}


function* verifySession() {
  try {
    const isLoggedIn = yield call(isAlreadyLogged);

    if (!isLoggedIn) yield put(setState({ isLoggedIn: false, entity: null }))
  } catch (e) {
    yield put(setState({ isLoggedIn: false, entity: null }))

  }
}

export default function* authSaga(): SagaIterator {
  yield takeLatest(types.SIGN_IN_REQUEST, signIn);
  yield takeLatest(types.VERIFY_SESSION, verifySession);
}