import types, { CreatePaymentAction, DeletePaymentAction, SetPaymentModalAction, UpdatePaymentAction } from './types';


export const createPayment = (payload: CreatePaymentAction['payload'], cb: CreatePaymentAction['cb']): CreatePaymentAction => ({
  type: types.CREATE_PAYMENT_REQUEST,
  payload,
  cb
});


export const setPaymentModal = (payload: SetPaymentModalAction['payload']): SetPaymentModalAction => ({
  type: types.SET_PAYMENT_MODAL,
  payload
})

export const deletePayment = (payload: DeletePaymentAction['payload'], cb: DeletePaymentAction['cb']): DeletePaymentAction => ({
  type: types.DELETE_PAYMENT_REQUEST,
  payload,
  cb
})

export const updatePayment = (payload: UpdatePaymentAction['payload'], cb: UpdatePaymentAction['cb']): UpdatePaymentAction => ({
  type: types.UPDATE_PAYMENT_REQUEST,
  payload,
  cb
})

