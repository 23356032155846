import { Defendant } from '../../interfaces/entities';
import types, { DefendantModalAction, UpdateDefendantSuccessAction } from './types';
import { DefendantType, Action, FetchDefendantsSuccessPayload } from './types';


const initialState: DefendantType = {
  defendants: null,
  pagesCount: 0,
  defendantsCount: 0,
  defendantModal: {
    active: false,
    edition: false,
    form: { legalPerson: true }
  },
  defendantFeedback: {
    active: false,
    success: true,
    message: ''
  }
}

export default function defendantReducer(state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_DEFENDANTS_SUCCESS: {
      const { defendants, pagesCount, defendantsCount } = payload as FetchDefendantsSuccessPayload;
      return { ...state, defendants, pagesCount, defendantsCount };
    }
    case types.SET_DEFENDANT_MODAL: {
      const value = payload as DefendantModalAction['payload'];
      return {
        ...state,
        defendantModal: {
          ...state.defendantModal,
          ...value,
          form: { ...state.defendantModal.form, ...(value?.form || {}) }
        }
      }
    }
    case types.UPDATE_DEFENDANT_SUCCESS: {
      const { id, fields } = payload as UpdateDefendantSuccessAction['payload'];
      let defendants = [...state.defendants || []];
      const defendantIndex = defendants.findIndex((df: Defendant) => df.id === id);
      if (defendantIndex) {
        defendants[defendantIndex] = { ...defendants[defendantIndex], ...fields };
      }
      return {
        ...state, defendants
      }
    }
    default:
      return state;
  }
}