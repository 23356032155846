import axios from '../axiosInstance';
import { LogController } from './types';




export const Log: LogController = {
  // * POST
  create: request => axios.post('/log', request, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(res => res.data),

  // * PUT
  update: request => axios.put('/log', request).then(res => res.data),

  // * GET 
  downloadAttachmentsZip: request => axios.get(
    '/downloadAttachmentsZip',
    { params: request, responseType: 'blob' }
  ).then(res =>
    new Blob([res.data], { type: 'application/zip' })
  ),


  // * DELETE 
  delete: request => axios.delete('/log', { data: request }).then(res => res.data),
  deleteAttachment: request => axios.delete('/log/attachment', { data: request }).then(res => res.data)
}