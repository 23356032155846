import React, { useEffect } from 'react';
import { ModalProps } from './types';
import { IoCloseCircleSharp } from 'react-icons/io5';


export default function Modal({ isActive, onClose = () => { }, children, header = null, footer = null }: ModalProps): JSX.Element {

  useEffect(() => {
    const html: HTMLHtmlElement = document.querySelector('html') as HTMLHtmlElement;
    if (isActive)
      html.style.overflowY = 'hidden';
    else
      html.style.overflowY = 'auto';

  }, [isActive]);
  useEffect(() => {
    const html: HTMLHtmlElement = document.querySelector('html') as HTMLHtmlElement;

    return () => { html.style.overflowY = 'auto' };
  }, []);
  return isActive ? (
    <div className="ModalComponent fixed top-0 h-full w-full left-0 z-50 flex justify-center items-center">
      <div onClick={onClose} className="absolute h-full w-full left-0 top-0" style={{ backgroundColor: 'rgba(0, 0, 0,.5)' }}></div>
      <div className="z-30 duration-200 modal-body relative bg-white w-5/12 sm:rounded-lg overflow-y-auto max-h-screen">
        {/* Header */}
        <div className={`w-full z-20 ${header ? 'shadow-sm' : ''} sticky top-0`}>
          {header}

          <div className="close absolute top-3 right-3 cursor-pointer" onClick={onClose}>
            <IoCloseCircleSharp className="text-gray-700" size="20px" />
          </div>
        </div>
        {/* Content */}
        <div className="pt-5 pb-5 pl-10 pr-10">{children}</div>
        {/* Footer */}
        {
          footer && (
            <div className="w-full shadow-md bg-white sticky bottom-0">
              {footer}
            </div>
          )
        }
      </div>
    </div>
  ) : (<></>)

}