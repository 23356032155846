import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


import { ReactComponent as BrandNavbarLogo } from '../../assets/images/brandLogo-navbar.svg';
import { roles } from '../../helpers/constants';
import { useUserRole } from '../../hooks/useUserRole';
import { User } from '../../interfaces/entities';
import { setState } from '../../store/auth/actions';
import { AuthType } from '../../store/auth/types';
import { State } from '../../store/reducers';
import Avatar from '../Avatar';
import Dropdown from '../ui/Dropdown';

export default function MemberNavbar(): JSX.Element {
  const { entity: user } = useSelector((state: State): AuthType => state.auth);
  const role = useUserRole();
  const history = useHistory();
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(setState({ entity: undefined, isLoggedIn: false }));
    window.localStorage.clear();
    window.location.reload();
  }
  function handleGoHome() {
    history.push('/member');
  }
  return (
    <nav className="w-full flex justify-center z-30 p-2 bg-primary sticky top-0 shadow-md">
      <div className="flex justify-center sm:justify-between flex-col sm:flex-row" style={{ width: '95%' }}>
        <div onClick={handleGoHome} className="navbar-start cursor-pointer flex justify-center mb-5 sm:mb-0 ">
          <BrandNavbarLogo />
        </div>
        <div className="navbar-end mb-5 sm:mb-0 flex items-center justify-center sm:justify-end">
          <Dropdown triggerContainer={{ style: { minWidth: 200 }, className: 'flex justify-center' }} trigger={(
            <div className="cursor-pointer text-white">
              <Avatar {...user as User} />
            </div>
          )}>
            <>
              {
                [roles.ADMINISTRATOR as string].includes(role) ? (
                  <div className="hover:bg-gray-200 duration-200 text-center p-2 cursor-pointer" onClick={() => history.push('/member/lawyers')}>Administración de abogados</div>

                ) : (null)
              }
              <div className="hover:bg-gray-200 duration-200 text-center p-2 cursor-pointer" onClick={handleSignOut}>Cerrar sesión</div>
            </>
          </Dropdown>
        </div>
      </div>

    </nav>
  )
}