import { Client, User } from "../../interfaces/entities";
import types, { Action, AuthType } from "./types";

const initialState: AuthType = {
  isLoggedIn: null,
  entity: null,
}


export default function authReducer(state = initialState, action: Action): AuthType {
  const { type, payload } = action;
  switch (type) {
    case types.SIGN_IN_SUCCESS: {
      const entity = payload as Client | User;
      return {
        entity,
        isLoggedIn: true
      }
    }
    case types.SET_AUTH_STATE: {
      return {
        ...state,
        ...payload
      }
    }
    default:
      return state;

  }
}