import React from 'react';
import { InputGroupProps } from './types';


export default function InputGroup({ children, className = '', ...extraProps }: InputGroupProps): JSX.Element {
  return (
    <div className={['flex', 'flex-col', 'items-start', 'w-full', 'outline-none', className].join(' ')} {...extraProps}>
      {children}
    </div>
  )
}