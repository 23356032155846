import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RiPencilLine } from 'react-icons/ri';


import { ProcessType } from '../../store/process/types';
import { State } from '../../store/reducers';
import { Input } from '../ui/Input';
import { ToDo } from '../../interfaces/entities';
import Button from '../ui/Button';
import { IoCheckmarkOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { setToDO } from '../../store/process/actions';
import Temporal from '../ui/Temporal';
import Message from '../ui/Message';
import { usePortal } from '../../hooks';
import { roles } from '../../helpers/constants';
import { useUserRole } from '../../hooks/useUserRole';



export default function ProcessToDo(): JSX.Element {

  const { gettedProcess: process } = useSelector((state: State): ProcessType => state.process);
  const { toDo } = process || {};

  const [edition, setEdition] = useState<boolean>(false);
  const Portal = usePortal(<></>, 'set_todo');
  const dispatch = useDispatch();
  const role = useUserRole();

  const [form, setForm] = useState<Partial<ToDo>>({
    content: '',
    endingDate: ''
  });
  function handleInputChange(e: ChangeEvent<{ value: string; name: string | undefined; type: string; }>) {
    e.persist();
    setForm((prev) => ({
      ...prev,
      [e.target.name as string]: e.target.value
    }))
  }
  function handleSave() {
    const portalMessage = ({ success, message }: { success: boolean, message: string | null }) => Portal.add(
      <Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg border-2 border border-gray-200 font-medium shadow-xl rounded-lg  text-center md:text-left"
          color="bg-white"
        >
          {!success ? `${message}` : `Se ha modificado el pendiente correctamente`}
        </Message>
      </Temporal>
    );

    dispatch(setToDO({
      ...form,
      processId: process?.id
    }, (res) => {
      portalMessage(res);
      setEdition(false);
    }));
  }


  useEffect(() => {
    setForm({
      content: toDo?.content ?? '',
      endingDate: moment.utc(toDo?.endingDate || new Date()).format('YYYY-MM-DD')
    });
  }, [toDo]);

  return (
    <div className="relative">
      <div className="bg-green-200 border-dashed border-2 border-green rounded-xl p-5 flex flex-col">
        <div className="content">
          {edition ? <Input multiline className="h-36	" onChange={handleInputChange} name="content" defaultValue={form.content} /> : form.content}
        </div>
        <div className="w-full flex justify-end items-center mt-5">
          <p className="mr-2">
            Termino:
          </p>

          <div>
            <Input className="w-32" type="date" onChange={edition ? handleInputChange : () => { }} name="endingDate" value={form?.endingDate} />
          </div>
        </div>
      </div>
      {([roles.ADMINISTRATOR, roles.LAWYER] as string[]).includes(role) && (
        <>
          {

            edition ? (
              <Button padding="p-1" shadow="" onClick={handleSave} style={{ right: '-1rem', top: '-1rem' }} className="absolute w-10 h-10 bg-white text-green rounded-full flex justify-center items-center">
                <IoCheckmarkOutline size="20px" />
              </Button>


            ) : (
                <Button padding="p-1" shadow="" onClick={() => setEdition(true)} style={{ right: '-1rem', top: '-1rem' }} className="absolute w-10 h-10 bg-white text-green rounded-full flex justify-center items-center">
                  <RiPencilLine size="20px" />
                </Button>

              )
          }</>
      )}
    </div >
  )
}