import React, { useState } from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { useDispatch } from 'react-redux';
import moment from 'moment';


import Button from '../ui/Button';
import Card from '../ui/Card';
import Dropdown from '../ui/Dropdown';
import { DropdownItem } from '../ui/Dropdown/Item';
import { LogCardProps } from './types';
import Avatar from '../Avatar';
import Confirm from '../ui/Confirm';
import { deleteLog, setAttachmentsModal, setLogModal } from '../../store/log/actions';
import Temporal from '../ui/Temporal';
import Message from '../ui/Message';
import { IoCheckmarkOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { usePortal } from '../../hooks';
import LawyerAccess from '../LawyerAccess';

// import { deleteLog } from '../../store/log/actions';



export default function LogCard({ handleReload, ...log }: LogCardProps) {
  const [confirmDeleteLogModal, setConfirmDeleteLogModal] = useState<boolean>(false);

  const {
    date,
    content,
    user,
    id,
  } = log;
  const Portal = usePortal();

  const dispatch = useDispatch();

  function handleDelete() {
    const portalMessage = ({ success, message }: { success: boolean, message: string | null }) => Portal.add(
      <Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium border-2 border border-gray-200 shadow-xl rounded-lg  text-center md:text-left"
          color="bg-white"
        >
          {!success ? `${message}` : `Se ha eliminado la Actividad correctamente`}
        </Message>
      </Temporal>
    );

    dispatch(deleteLog({ id: id as number }, ({ success, message }) => {
      handleReload();
      portalMessage({ success, message })
    }))

  }
  function handleUpdateLog() {
    dispatch(setLogModal({ active: true, edition: true, form: { ...log } }))
  }
  function handleOpenAttachmentsModal(privateLogs: boolean = false): void {
    dispatch(setAttachmentsModal({
      active: true, log: log, private: privateLogs
    }))
  }

  return (<>
    {
      confirmDeleteLogModal && (
        <Confirm title={<p className="text-2xl">Estas seguro de eliminar esta Actividad?</p>} content={
          <p className="mt-2">Si eliminas esta actividad, no podrás recuperarla en un futuro</p>
        } onAccept={handleDelete} onDeny={() => setConfirmDeleteLogModal(false)} />
      )
    }
    <Card className="mt-2 border-2 border-primary" >
      <div className="w-full px-5 py-2 flex flex-wrap justify-between">
        <div className="w-full md:w-1/2 flex-grow">
          <p className="text-lg font-bold mb-2">{moment.utc(new Date(date || Date.now())).format('DD/MM/YYYY')}</p>
          <div className="flex flex-row w-full break-words items-start md:items-center">
            <p className="text-sm w-full sm:text-base font-normal break-words flex-grow md:flex-none mr-2">
              {content}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap flex-row md:mt-0 w-full md:w-auto justify-end w-full xl:w-auto">
          <div className="flex-auto ml-2 md:ml-5 flex items-end lg:items-end flex-col">
            <Avatar {...{ ...user, type: 'user' }} />
            <div className="flex sm:flex-col md:mt-5 mb-2 md:mb-0 items-end">
              <div>
                <LawyerAccess>
                  <Button onClick={() => handleOpenAttachmentsModal(true)} className="h-auto  md:h-15 w-15 ml-2 md:ml-0 text-red bg-transparent border-red">
                    <AiOutlinePaperClip size="20px" />
                  </Button>
                </LawyerAccess>
                <Button onClick={() => handleOpenAttachmentsModal(false)} className="h-auto  md:h-15 w-15 ml-2 text-green bg-transparent border-green">
                  <AiOutlinePaperClip size="20px" />
                </Button>
              </div>
              <div className="w-9 ml-5 md:ml-0 md:w-auto">
                <LawyerAccess>
                  <Dropdown dropdownList={{ width: 'w-auto', style: { marginTop: '-10px' } }} trigger={<Button border="border-none" padding="px-1 py-3" shadow="shadow-none" className="w-4 md:w-auto"><BiDotsHorizontalRounded size="30px" /></Button>}>
                    <DropdownItem textAlign="text-center" padding="px-5 py-2 bg-gray-100" onHoverClass="hover:bg-gray-200" onClick={handleUpdateLog}>Editar</DropdownItem>
                    <DropdownItem textAlign="text-center" padding="px-5 py-2 bg-gray-100" onHoverClass="hover:bg-gray-200" onClick={() => setConfirmDeleteLogModal(true)}>Eliminar</DropdownItem>
                  </Dropdown>
                </LawyerAccess>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </>
  )
}