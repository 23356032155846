import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthEntity, AuthType } from "../store/auth/types";
import { State } from "../store/reducers";

export function useEntityFormattedName(user: Partial<AuthEntity> | undefined = undefined) {
  const [
    { prefix, formattedName },
    setFormattedName
  ] = useState<{ prefix: string; formattedName: string }>({ prefix: '', formattedName: '' })
  const authState = useSelector((state: State): AuthType => state.auth);
  let { name, type, role } = authState.entity as AuthEntity;
  if (user) {
    name = user?.name ?? ''
    if (user?.role)
      role = user?.role ?? '2';
    type = user?.type || 'client';
  }

  function getFormattedName() {
    const prefix: string = type === 'user' ? (role === "2" ? 'Abg' : '') : ''
    const splittedName: string[] = name?.split(' ') as string[];
    const formattedName: string = splittedName.length > 2 ? `${splittedName[0]} ${splittedName[2]}` : name as string;

    setFormattedName({
      prefix,
      formattedName
    });

  }
  useEffect(() => {
    getFormattedName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return { prefix, formattedName };
}
