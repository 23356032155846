import React, { useEffect, useState } from 'react';
import Button from '../Button';
import { Tab, TabsProps } from './types';


export default function Tabs<TabValueType>({ tabs, onSelect = (tab: Tab<TabValueType>) => { }, defaultTab = 0, className = '', CustomButtonComponent = null }: TabsProps<TabValueType>): JSX.Element {
  const [selected, selectTab] = useState<number>(defaultTab);
  function handleSelect(tab: Tab<TabValueType>, index: number) {
    selectTab(index);
    onSelect(tab);
  }


  useEffect(() => {
    onSelect(tabs[defaultTab ?? 0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div className={`flex flex-wrap  ${className}`}>
    {tabs.map((tab, index) => (
      CustomButtonComponent ?
        (
          <React.Fragment key={index}>
            <CustomButtonComponent type="button" active={Boolean(selected === index)} onClick={() => handleSelect(tab, index)}><>{tab.content}</></CustomButtonComponent>
          </React.Fragment>
        ) : (
          <Button type="button" className={`mr-2 mt-2 sm:mt-0 w-full sm:w-auto rounded-full text-sm ${selected === index ? 'bg-primary text-white shadow-md ' : ''}`} key={index} style={{ padding: '5px 10px' }} onClick={() => handleSelect(tab, index)}><>{tab.content}</></Button>
        )
    ))}
  </div>
}