import React from 'react';

import Button from '../Button';
import Modal from '../Modal';
import ConfirmModalWrapper from './styles';
import { ConfirmProps } from './types';

export default function Confirm({ onAccept, onDeny, title = '', content }: ConfirmProps): JSX.Element {

  return (
    <ConfirmModalWrapper>
      <Modal onClose={onDeny} isActive={true}>
        <div className="w-full">
          {title}
          {content}
          <div className="w-full mt-10 flex justify-end items-center">
            <Button onClick={onDeny} className="text-gray-500 font-medium mr-5" border="" shadow="">Cancelar</Button>
            <Button onClick={onAccept} shadow="" border="border-gray-200" className="bg-primary text-white">Aceptar</Button>
          </div>
        </div>
      </Modal>
    </ConfirmModalWrapper>
  )
}