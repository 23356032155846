import React, { useEffect } from 'react';
import { IoChevronBackOutline, IoPersonAdd, IoRefresh } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CreateUser from '../../components/CreateUser';
import LawyerCard from '../../components/LawyerCard';

import MemberNavbar from '../../components/MemberNavbar';
import Button from '../../components/ui/Button';

import { State } from '../../store/reducers';

import * as userActions from '../../store/user/actions';
import { UserType } from '../../store/user/types';




export default function LawyersPage(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { lawyers } = useSelector((state: State): UserType => state.user);


  function handleFetchLawyers() {
    dispatch(userActions.fetchLawyers());

  }

  function handleOpenCreateLawyer(): void {
    dispatch(userActions.setLawyerModal({ active: true }))
  }

  useEffect(() => {
    handleFetchLawyers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>
    <MemberNavbar />
    <CreateUser />
    <div className="w-full text-center my-10">
      <h1 className="text-5xl font-bold text-primary">IMPEGNO S.A.S</h1>
      <h1 className="text-2xl font-bold text-primary">Abogados</h1>
    </div>
    <div className="w-full pr-2 pl-2 md:pr-20 md:pl-20 pb-10 flex justify-end">
      <Button className="bg-primary flex text-white items-center" onClick={handleOpenCreateLawyer}>
        <>
          <IoPersonAdd size="18px" className="mr-2" />
          Agregar abogado
        </>
      </Button>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full pr-2 pl-2 md:pr-20 md:pl-20 pb-10">

      {
        lawyers.map((lw) => (
          <React.Fragment key={lw.id}>
            <LawyerCard onDisabledChange={() => { }} {...lw} />
          </React.Fragment>
        ))
      }
    </div>
    <div className="flex my-5 items-center flex-wrap items-start md:flex-row pr-2 w-full justify-between pl-2 md:pr-20 md:pl-20 mt-20 w-full">
      <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15" onClick={history.goBack}>
        <IoChevronBackOutline />
      </Button>
      <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15" onClick={handleFetchLawyers}><IoRefresh /></Button>

    </div>
  </div>
}