import React, { ChangeEvent, useState } from 'react';
import _ from 'lodash'

import { ToggleSwitchProps } from './types';



export default function ToggleSwitch({ onChange = () => { }, defaultValue = false, label = '' }: ToggleSwitchProps) {
  const [isChecked, setIsChecked] = useState<boolean>(defaultValue);
  const [id] = useState<string>(_.uniqueId('tswitch_'))


  function handleChange(e: ChangeEvent<{ checked: boolean }>) {
    e.stopPropagation();
    e.persist();
    setIsChecked(e.target.checked)
    onChange(e.target.checked);
  }


  return (
    <div className="flex flex-col">
      <label htmlFor={id} className="mt-3 inline-flex items-center cursor-pointer">
        <span className="relative">
          <span className={`block w-10 h-6  ${isChecked ? 'bg-green border border-transparent' : 'bg-white border border-black'} transition-transform duration-300 ease-in-out  rounded-full shadow-inner`}></span>
          <span className={`absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out ${isChecked ? 'bg-white transform translate-x-full' : 'bg-black'}`}>
            <input id={id} onChange={handleChange} defaultChecked={defaultValue} type="checkbox" className="absolute opacity-0 w-0 h-0" />
          </span>
        </span>
        {typeof label === typeof '' ? (
          <span className="ml-3 text-sm">{label}</span>
        ) : label}
      </label>

    </div>
  )
}