import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { Auth, User } from '../../api';
import { State } from '../reducers';
import { fetchLawyersSucces, fetchLawyers } from './actions';
import types, { CreateLawyerAction, UpdateLawyerAction, UserType } from './types';




function* fetchLawyersSaga(): SagaIterator {
  try {
    const { success, lawyers } = yield call(User.fetchLawyers);

    if (success)
      yield put(fetchLawyersSucces(lawyers));

  } catch (e) {
    console.log(e);

  }
}

function* createLawyer({ payload, cb }: CreateLawyerAction): SagaIterator {
  try {
    const { form: fields } = yield select((state: State): UserType['userModal'] => state.user.userModal);

    const formData = new FormData();
    formData.append('role', '2');

    if (fields?.name) formData.append('name', fields.name);
    if (fields?.email) formData.append('email', fields.email);
    if (fields?.password) formData.append('password', btoa(fields.password));

    if (payload?.image) formData.append('image', payload.image);


    const res = yield call(Auth.register, formData);
    if (res?.success) {
      yield put(fetchLawyers());
    }
    yield call(cb, res);

  } catch (e) {
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });

  }
}

function* updateLawyer({ payload, cb }: UpdateLawyerAction): SagaIterator {
  try {
    const formData = new FormData();
    let { password, image, ...rest } = payload.fields

    if (password) password = btoa(password);
    if (image) {
      formData.append('image', image);
    }


    formData.append('fields', JSON.stringify({ ...rest, password }));
    formData.append('id', String(payload.id));
    const res = yield call(User.update, formData);
    if (res?.success) {
      yield put(fetchLawyers());
    }
    yield call(cb, res);
  } catch (e) {
    yield call(cb, { success: false, message: 'Ha ocurrido un error desconocido' });
  }
}

export default function* UserSaga(): SagaIterator {
  yield takeLatest(types.FETCH_LAWYERS_REQUEST, fetchLawyersSaga);
  yield takeLatest(types.UPDATE_LAWYER_REQUEST, updateLawyer);
  yield takeLatest(types.CREATE_LAWYER_REQUEST, createLawyer);
}