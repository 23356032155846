import styled from 'styled-components';


const CreateProcessWrapper = styled.div`
  .modal-body{
    width: 70vw;
    max-height: 85vh;
    @media screen and (min-width: 769px) and (max-width: 1200px){
      width: 80vw;
      max-height: 100vh;

    }
    @media screen and (max-width: 768px){
      width: 100vw !important;
      
    max-height: 100vh;

    }
  }

`

export default CreateProcessWrapper; 