import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoCheckmarkOutline, IoChevronBackOutline, IoCloseCircleSharp } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { RiMoneyDollarCircleFill, RiPencilLine } from 'react-icons/ri';

import { State } from '../../store/reducers';
import { updateProcess } from '../../store/process/actions';
import { ProcessType } from '../../store/process/types';
import { setProcessModal } from '../../store/process/actions';

import { Label } from '../ui/Input';
import Button from '../ui/Button';
import Divider from '../ui/Divider';
import Progress from '../ui/Progress';
import Select from '../ui/Select';
import { Option } from '../ui/Select/types';
import Message from '../ui/Message';
import Temporal from '../ui/Temporal';
import { ProcessDataProps } from './types';
import LawyerAccess from '../LawyerAccess';

import { Process } from '../../interfaces/entities';
import { processStatus, processStatusColors, useFormattedAmount, usePortal, useProcessStatus } from '../../hooks';

export default function ProcessData({ handleReload, setPaymentsList }: ProcessDataProps): JSX.Element {
  let { gettedProcess: process } = useSelector((state: State): ProcessType => state.process);
  const history = useHistory();
  const dispatch = useDispatch();
  const Portal = usePortal();
  const demandedAmount = useFormattedAmount(process?.demandedAmount || 0); // cuantia
  const debtAmount = useFormattedAmount(process?.debtAmount || 0); // deuda

  const { color: statusColor } = useProcessStatus(Number(process?.status));
  const statusOptions: Option<Process['status']>[] = Object.keys(processStatus).map((status: any) => ({
    text: (
      <div className="w-full flex justify-between">
        <span className="font-bold mr-2 truncate" style={{ color: processStatusColors[status] }}>{processStatus[status as number]}</span>
        <div className="w-1"></div>
        <span className="text-right font-bold" style={{ color: processStatusColors[status] }}>{status}%</span>
      </div>
    ),
    value: status
  }));

  function handleStatusChange(status: Process['status']) {
    const portalMessage = ({ success, message }: { success: boolean, message: string | null }) => Portal.add(
      <Temporal duration={6000}>
        <Message
          icon={success ? <IoCheckmarkOutline size="30px" /> : <IoCloseCircleSharp color="red" size="30px" />}
          container={{
            className: "fixed bottom-10  z-20 w-2/3  z-50",
            style: { marginLeft: 'calc(calc(100% / 3) / 2)' }
          }}
          className="text-lg font-medium shadow-xl border-2 border border-gray-200 rounded-lg  text-center md:text-left"
          color="bg-white"
        >
          {!success ? `${message}` : `Se ha modificado el proceso correctamente`}
        </Message>
      </Temporal>
    );
    dispatch(updateProcess({ id: process?.id as Process['id'], fields: { status } }, (res) => {
      portalMessage(res);
      if (res.success) handleReload();
    }));
  }

  function handleOpenEditProcess() {
    dispatch(setProcessModal({ active: true, edition: true, form: { ...process } }));
  }


  return (
    <>
      <div className="flex flex-col py-10 pl-5 pr-5 items-start text-left">
        <div className="w-full text-5xl wrap ">
          <h1 className="font-bold text-3xl text-primary w-full break-word m-0 pb-2">
            {process?.demandantName}
          </h1>
        </div>
        <div className="flex flex-wrap mt-5">
          <Label weight="font-normal" className="text-lg" color="text-black">RUC/CI:</Label>
          <p className="ml-2 text-lg break-all">{process?.demandantOfficialId || 'Sin información'}</p>
        </div>
        <div className="flex flex-wrap mt-5">
          <Label weight="font-normal" className="text-lg" color="text-black">Proceso:</Label>
          <p className="ml-2 text-lg break-all">{process?.internalId || 'Sin información'}</p>
        </div>

        <div className="flex flex-wrap mt-5">
          <Label weight="font-black" className="text-2xl" color="text-black">Cuantia:</Label>
          <p className="ml-2 text-lg break-all">${demandedAmount || 'Sin información'}</p>
        </div>
        <div className="flex flex-wrap mt-5">
          <Label weight="font-normal" className="text-lg" color="text-black">Deuda:</Label>
          <p className="ml-2 text-lg break-all">${debtAmount || 'Sin información'}</p>
        </div>

        <Divider className="mt-5 opacity-60" color="bg-black" width="w-3/4" height="h-0.5" />
        <div className="flex flex-wrap items-center justify-between mt-5 w-full">
          <Label weight="font-black" className="text-xl" color="text-black">
            Información
          </Label>
          <LawyerAccess>

            <Button onClick={handleOpenEditProcess}>
              <RiPencilLine size="20px" />
            </Button>
          </LawyerAccess>

        </div>
        {
          process?.client?.legalPerson && (
            <div className="flex flex-col flex-wrap items-start mt-3 w-full">
              <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
                Gerente General:
              </Label>
              <Label weight="font-normal" className="text-lg p-0 m-0 break-all" color="text-black">
                <>{process?.client?.generalManager}</>
              </Label>
            </div>
          )
        }
        <div className="flex flex-col flex-wrap items-start mt-3 w-full">
          <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
            Dirección:
          </Label>
          <Label weight="font-normal" className="text-lg p-0 m-0" color="text-black">
            <>{process?.client?.address}</>
          </Label>
        </div>
        <div className="flex flex-col flex-wrap items-start mt-3 w-full">
          <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
            Número:
          </Label>
          <Label weight="font-normal" className="text-lg p-0 m-0 break-all" color="text-black">
            <>{process?.client?.phone}</>
          </Label>
        </div>

        <div className="flex flex-col flex-wrap items-start mt-3 w-full">
          <Label weight="font-normal" style={{ fontSize: 13 }} className="p-0 m-0" color="text-gray-400">
            Mail:
          </Label>
          <Label weight="font-normal" className="text-lg p-0 m-0 break-all" color="text-black">
            <>{process?.client?.email}</>
          </Label>
        </div>

        <Divider className="my-5 pacity-60" color="bg-black" width="w-3/4" height="h-0.5" />
        <div className="flex flex-col flex-wrap items-start mt-3 w-full">
          <Label weight="font-bold text-xl mb-5" className="p-0 m-0" color="text-black">
            Status:
          </Label>
          <div className="flex items-center w-full md:w-3/4">
            <div className="flex-1 flex-grow">
              <div className="flex-auto md:w-full">
                <Progress value={Number(process?.status)} color={statusColor} />
              </div>
            </div>
            <p className="text-base font-extrabold ml-5 break-all" style={{ color: statusColor }} >{process?.status}%</p>
          </div>
          <LawyerAccess>
            <div className="w-3/4 mt-5">
              <Select<Process['status']> options={statusOptions} defaultValue={statusOptions.find((status) => Number(status?.value) === Number(process?.status))} onChange={handleStatusChange} />
            </div>
          </LawyerAccess>
        </div>
        <LawyerAccess>
          <Button onClick={() => setPaymentsList(true)} className="text-white bg-primary px-5 mt-5">
            <div className="flex items-center justify-center">
              <RiMoneyDollarCircleFill size="22px" className="mr-2" />
            Historial de Pagos
          </div>
          </Button>
        </LawyerAccess>
        <div className="flex flex-col flex-wrap items-start mt-20 w-full">
          <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15" onClick={() => history.push(`/member/client/${process?.client?.id}`)}>
            <IoChevronBackOutline size="30px" />
          </Button>
        </div>
      </div>
    </>
  )
}
