import React, { useEffect, useState } from 'react';
import { IoAddSharp, IoInformationCircleSharp, IoRefresh } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';

import { ClientType } from '../../store/client/types';
import { State } from '../../store/reducers';
import ProcessCard from '../ProcessCard';
import Button from '../ui/Button';

import { ProcessListProps } from './types';

import _ from 'lodash';
import { Process } from '../../interfaces/entities';
import Pagination from '../ui/Pagination';
import Message from '../ui/Message';
import { setProcessModal } from '../../store/process/actions';
import { roles } from '../../helpers/constants';
import { useUserRole } from '../../hooks/useUserRole';
import LawyerAccess from '../LawyerAccess';


export default function ProcessList({ handleReload, filters }: ProcessListProps) {
  const client = useSelector((state: State): ClientType['gettedClient'] => state.client.gettedClient)
  const paginationState = useState<number>(0);
  const [processes, setProcesses] = useState<Partial<Process>[][]>([]);

  const dispatch = useDispatch();

  const role = useUserRole();

  useEffect(() => {
    setProcesses(_.chunk(client?.processes || [], 5));
  }, [client]);

  useEffect(() => {
    if (client?.processes) {
      const filtersAux: Partial<ProcessListProps['filters']> = {};
      if (filters?.internalId) filtersAux.internalId = filters.internalId;
      if (filters?.status !== undefined) filtersAux.status = filters?.status;
      const processesAux = client?.processes
        .filter((process: Partial<Process>) => process?.internalId ? process.internalId?.toLowerCase()?.includes(filtersAux?.internalId || '') : true)
        .filter((process: Partial<Process>) => filtersAux?.status ? String(process.status) === String(filtersAux?.status) : true)
      setProcesses(_.chunk(processesAux, 5));
      paginationState[1](0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.processes, filters]);
  function openCreateProcessModal() {
    dispatch(setProcessModal({
      active: true,
      form: { clientId: client?.id }
    }))
  }

  return (
    <div className="p-2 md:p-10">
      <div className="w-full flex mb-8 justify-between items-center flex-col md:flex-row">
        <h3 className="text-2xl font-bold text-center md:text-left mb-5 md:mb-0">
          Procesos judiciales
        </h3>
        <LawyerAccess>
          <div>
            <Button onClick={openCreateProcessModal} className="bg-primary text-white"><IoAddSharp size="25px" /></Button>
          </div>
        </LawyerAccess>

      </div>
      {processes[paginationState[0]]?.map((process, index) => (
        <React.Fragment key={index}>
          <ProcessCard {...process} />
        </React.Fragment>
      ))}
      {Boolean(processes.length) && (
        <div className="justify-between flex w-full mt-10">
          <div className="w-min">
            <Pagination pagesCount={processes?.length || 0} state={paginationState} />
          </div>
          <div>
            <Button className="text-white bg-primary rounded-lg w-15 text-2xl h-15" onClick={handleReload}>
              <IoRefresh />
            </Button>
          </div>
        </div>
      )}
      <LawyerAccess>
        <>
          {
            processes?.length === 0 && (client?.processes?.length || 0) > 0 ? (
              <Message icon={<IoInformationCircleSharp size="30px" color="skyblue" />} rounded>
                No se han encontrado procesos con los filtros seleccionados
              </Message>
            ) : client?.processes?.length === 0 && (
              <Message icon={<IoInformationCircleSharp size="30px" color="skyblue" />} rounded>
                <div className="w-full flex flex-col md:flex-row md:justify-between items-center">
                  <div>
                    Crea el primer proceso para <strong>{client?.name}</strong>
                  </div>
                  <div>
                    <Button style={{ backgroundColor: 'skyblue' }} onClick={openCreateProcessModal} className="text-white font-bold ">Crear proceso</Button>
                  </div>
                </div>
              </Message>
            )
          }
        </>
      </LawyerAccess>

      {role === roles.CLIENT && client?.processes?.length === 0 && (
        <Message icon={<IoInformationCircleSharp size="30px" color="skyblue" />} rounded>
          Usted no tiene ningun proceso judicial asignado.
        </Message>
      )}
    </div>
  )
}