import { ProcessController } from "./types";
import axios from '../axiosInstance';


export const Process: ProcessController = {
  // * POST
  create: request => axios.post('/process', request).then(res => res.data),

  // * PUT
  update: request => axios.put('/process', request).then(res => res.data),

  // * GET
  getProcess: ({ id }) => axios.get(`/process/${id}`).then(res => res.data)
}