import React from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useFormattedAmount, useProcessStatus } from '../../hooks';
import { setLogModal } from '../../store/log/actions';
import { setProcessModal } from '../../store/process/actions';
import LawyerAccess from '../LawyerAccess';


import Button from '../ui/Button';
import Card from '../ui/Card';
import Dropdown from '../ui/Dropdown';
import { DropdownItem } from '../ui/Dropdown/Item';
import Progress from '../ui/Progress';
import { ProcessCardProps } from './types';


export default function ProcessCard(props: ProcessCardProps) {
  const {
    demandantName,
    demandedAmount,
    debtAmount,
    defendant,
    status = 0,
    internalId,
    id
  } = props;
  const formattedDemandedAmount = useFormattedAmount(demandedAmount || '');
  const formattedDebtAmount = useFormattedAmount(debtAmount || '');
  const { color: statusColor } = useProcessStatus(Number(status));
  const dispatch = useDispatch();
  const history = useHistory();



  function handleOpenUpdateProcessModal() {
    dispatch(setProcessModal({
      active: true,
      edition: true,
      form: { ...props }
    }))
  }

  function handleRedirect() {
    history.push(`/member/process/${id}`);
  }

  function handleOpenCreateLog() {
    dispatch(setLogModal({ active: true, form: { internalId: internalId, processId: id } }));

  }
  return (
    <Card className="mb-5 border-2 border-primary relative">
      <div onClick={handleRedirect} className="w-full  pb-10 pt-5 pl-5 pr-5 cursor-pointer flex flex-wrap justify-between items-center">
        <div className="w-full  min-h-full md:w-1/2">
          <p className="text-xl font-normal mb-2"> <strong>{demandantName}</strong> | {internalId}</p>
          <div className="flex flex-col w-full justify-start md:justify-center">
            <div className="flex">
              <p className="text-sm sm:text-base font-normal md:flex-none mr-2"> {defendant?.name || <span className="text-gray-500 font-bold">Sin información</span>} | </p>
              <p className="md:ml-2 text-sm sm:text-base font-normal md:flex-none mr-2">{defendant?.officialId || <span className="text-gray-500 font-bold">Sin información</span>}</p>
            </div>
            <div className="flex items-center mt-3 w-full md:w-auto">
              <div className="flex-auto md:w-32">
                <Progress value={Number(status)} color={statusColor} />
              </div>
              <p className="text-base font-extrabold ml-5 break-all" style={{ color: statusColor }}>{status}%</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-h-full flex-wrap justify-between items-center relative pl-5 pr-2 pb-5 lg:pl-0 lg:pr-0 lg:pb-0 lg:absolute right-2 flex-wrap top-0 flex-row md:mt-0 w-full md:w-auto mt-5">
        <div className="flex flex-row md:flex-col items-center md:justify-center md:items-end">
          <div className="flex">
            <p className="text-sm md:text-xl  font-normal md:font-medium">Cuantía: </p>
            <p className="text-sm md:text-xl font-normal font-extrabold ml-2">${formattedDemandedAmount}</p>
          </div>
          <div className="flex ml-2 md:ml-0">
            <p className="text-sm md:text-lg font-medium">Deuda: </p>
            <p className="text-sm md:text-xl font-extrabold ml-2">${formattedDebtAmount}</p>
          </div>
        </div>
        <LawyerAccess>

          <div className="f
          lex-auto md:mt-5 md:mt-0 ml-2 md:ml-5 flex md:items-end flex-row-reverse items-center  justify-start md:flex-col">
            <Button onClick={handleOpenCreateLog} className="h-auto  md:h-15 w-15 ml-2 md:ml-0 text-white bg-primary">
              <AiOutlinePaperClip size="23px" />
            </Button>
            <div className="w-9 md:w-auto">
              <Dropdown dropdownList={{ width: 'w-auto', style: { marginTop: '-10px' } }} trigger={<Button border="border-none" padding="px-1 py-3" shadow="shadow-none" className="w-4 md:w-auto"><BiDotsHorizontalRounded size="30px" /></Button>}>
                <DropdownItem textAlign="text-center" padding="px-5 py-2 bg-gray-100" onHoverClass="hover:bg-gray-200" onClick={handleOpenUpdateProcessModal}>Editar</DropdownItem>
              </Dropdown>
            </div>

          </div>
        </LawyerAccess>

      </div>
    </Card>
  )
}